export const addItem = () => {
  const sItem = this.selectedItem;
  const itemRecord = this.itemRecord;
  const self = this;
  const details = {
    ItemCode: sItem.id,
    Dscription: itemRecord.Dscription,
    CodeBars: itemRecord.CodeBars || null,
    SerialNum: itemRecord.SerialNum || null,
    Quantity: self.itemRecord.Quantity || null,
    DelivrdQty: itemRecord.DelivrdQty || null,
    UseBaseUn: itemRecord.UseBaseUn || null,
    PackQty: itemRecord.PackQty || null,
    PriceBefDi: self.itemRecord.PriceBefDi || null,
    DiscPrcnt: self.itemRecord.DiscPrcnt || null,
    Rate: null,
    Price: self.itemRecord.Price || null,
    TaxCode: self.itemRecord.TaxCode || null,
    PriceAfVAT: self.itemRecord.PriceAfVAT || null,
    LineTotal: self.itemRecord.LineTotal || null,
    WhsCode: itemRecord.WhsCode || null,
    ShipDate: itemRecord.ShipDate || null,
    SlpCode: itemRecord.SlpCode || null,
    Commission: itemRecord.Commission || null,
    TreeType: itemRecord.TreeType || "N",
    LineStatus: itemRecord.LineStatus || null,
    TargetType: itemRecord.TargetType || -1,
    TargetEntry: itemRecord.TargetEntry || null,
    BaseType: itemRecord.BaseType || -1,
    BaseRef: itemRecord.BaseRef || null,
    BaseEntry: itemRecord.BaseEntry || null,
    BaseLine: itemRecord.BaseLine || null,
    SpecPrice: itemRecord.SpecsPrice || "N",
    VatSum: self.itemRecord.VatSum || null,
    GrssProfit: self.GrssProfit || null,
    PoTrgNum: itemRecord.PoTrgNum || null,
    BackOrdr: self.itemRecord.BackOrdr | 278179588 | null,
    FreeTxt: itemRecord.FreeTxt || null,
    PickStatus: itemRecord.PickStatus || null,
    UomCode: self.itemRecord.UomCode || null,
    unitMsr: itemRecord.unitMsr || null,
    NumPerMsr: itemRecord.NumPerMsr || null,
    Text: itemRecord.Text || null,
    GTotal: self.itemRecord.GTotal || null,
    UgpCode: itemRecord.UgpCode || null,
    AgrNo: itemRecord.AgrNo || null,
    LinePoPrss: self.itemRecord.LinePoPrss || "N",
    OnHand: itemRecord.OnHand || null,
    IsCommited: itemRecord.IsCommited || null,
    OnOrder: itemRecord.OnOrder || null,
    OpenQty: self.itemRecord.Quantity || null,
    InvQty: self.itemRecord.Quantity || null,
    OpenInvQty: self.itemRecord.Quantity || null,
    OwnerCode: null,
    TrnsCode: null,
    OrigItem: null,
    GPTtlBasPr: null,
    GrossBuyPr: null,
    OcrCode5: null,
    OcrCode4: null,
    OcrCode3: null,
    OcrCode2: null,
    OcrCode: null,
    AcctCode: null,
  };

  this.saleItemsData.push(details);

  // clear fields
  this.selectedItem = {};
  this.itemRecord = {};
  this.Quantity = null;
  this.PriceBefDi = null;
  this.DiscPrcnt = null;
  this.TaxCode = null;
  this.ShipDate = null;
  this.BackOrdr = "N";
  this.LinePoPrss = "N";
  this.Commission = null;
  this.dialog = false;
};
