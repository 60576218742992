<template>
  <v-container fluid>
    <!-- start of header fields -->
    <v-row
      v-if="headerFields"
      dense
    >
      <v-col
        v-for="(dataField, i) in headerFields"
        v-bind:key="i"
        :cols="dataField.ColumnWidth"
        v-show="dataField.Visible == 'Y'"
      >
        <v-text-field
          dense
          outlined
          v-if="dataField.FieldType == 'textfield' && dataField.FieldName == 'CardCode'"
          v-model="UserInput[i]"
          :label="dataField.Label"
          autocomplete="nope"
          :readonly="cardCodeIsReadOnly"
          :append-icon="'mdi-airballoon'"
          @click:append="openBp"
        >  </v-text-field>
        
        <v-text-field
          dense
          outlined
          v-else-if="dataField.FieldType == 'textfield' && dataField.FieldName == 'DocNum'"
          :readonly="docReadonly == 'Y'? trueValue: falseValue"
          autocomplete="nope"
          :filled="docReadonly == 'Y'? trueValue: falseValue"
          v-model="UserInput[i]"
          :label="dataField.Label"
        ></v-text-field>

        <v-text-field
          v-else-if="dataField.FieldName == 'NumAtCard'"
          dense
          outlined
          autocomplete="nope"
          v-show="dataField.FieldType == 'textfield'"
          v-model="UserInput[i]"
          :label="dataField.Label"
        ></v-text-field>

        <v-text-field
          v-else-if="dataField.FieldName == 'LicTradNum'"
          dense
          outlined
          autocomplete="nope"
          v-show="dataField.FieldType == 'textfield'"
          v-model="UserInput[i]"
          label="KRA PIN"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          v-else-if="dataField.FieldType == 'textfield'"
          :readonly="dataField.Readonly  == 'Y'? trueValue: falseValue"
          :filled="dataField.Readonly  == 'Y'? trueValue: falseValue"
          v-model="UserInput[i]"
          autocomplete="nope"
          :label="dataField.Label"
        ></v-text-field>

        <text-area
          v-if="dataField.FieldType == 'textarea'"
          :setup="dataField"
        ></text-area>

        <v-autocomplete
          v-if="dataField.FieldName == 'Series'"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="UserInput[i]"
          label="Num Series"
          :items="seriesData"
          item-text="SeriesName"
          item-value="id"
          @change="setDocNumber(Series)"
        ></v-autocomplete>

        <v-autocomplete
          v-else-if="dataField.FieldName == 'ReqType'"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="UserInput[i]"
          label="Req. Type"
          :items="reqTypes"
          item-text="name"
          item-value="value"
        ></v-autocomplete>

        <v-autocomplete
          v-else-if="dataField.FieldName == 'Requester' && ReqType== 12"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="UserInput[i]"
          label="Requester"
          :items="usersData"
          item-text="name"
          @change="autoPopulateBranch(Requester)"
          item-value="id"
        ></v-autocomplete>

        <v-autocomplete
          v-else-if="dataField.FieldName == 'Requester' && ReqType== 171"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="UserInput[i]"
          label="Requester"
          :items="ohems"
          :item-text="item => item.firstName +'    '+ item.lastName"
          item-value="id"
          @change="autoPopulateBranch(Requester)"
        ></v-autocomplete>

<!--        <v-autocomplete-->
<!--          v-else-if="dataField.FieldName == 'Branch'"-->
<!--          dense-->
<!--          outlined-->
<!--          v-show="dataField.FieldType == 'select'"-->
<!--          v-model="requesterBranch"-->
<!--          label="Req. Branch"-->
<!--          :items="branchesData"-->
<!--          item-text="BPLName"-->
<!--          item-value="ExtRef"-->
<!--        ></v-autocomplete>-->

        <v-autocomplete
          v-else-if="dataField.FieldName == 'Department'"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="UserInput[i]"
          label="Department"
          :items="deparmentData"
          item-text="Name"
          item-value="id"
        ></v-autocomplete>

        <v-autocomplete
          v-else-if="dataField.FieldName == 'BPLId'"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="UserInput[i]"
          label="Branch"
          :items="branchesData"
          item-text="BPLName"
          item-value="BPLId"
        ></v-autocomplete>

        <v-autocomplete
          v-else-if="dataField.FieldName == 'NumAtCard2'"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="UserInput[i]"
          label="Dealer"
          :items="delears"
          @change="dealerSelected(NumAtCard2)"
          item-text="Name"
          item-value="Name"
        ></v-autocomplete>

        <v-autocomplete
          v-else-if="dataField.FieldName == 'PriceList'"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="GroupNum"
          label="Price List"
          :items="pricelistData"
          item-text="ListName"
          item-value="id"
        ></v-autocomplete>

<!--        <v-autocomplete-->
<!--          v-else-if="dataField.FieldName == 'U_PCash'"-->
<!--          dense-->
<!--          outlined-->
<!--          v-show="dataField.FieldType == 'select'"-->
<!--          v-model="U_PCash"-->
<!--          :label="dataField.Label"-->
<!--          :items="pettyCash"-->
<!--          item-text="name"-->
<!--          item-value="value"-->
<!--        ></v-autocomplete>-->

        <DocDatePicker
          v-if="dataField.FieldType == 'date'"
          @date="setDate"
          :myDate="record[dataField.FieldName]"
          :fieldName="dataField.FieldName"
          :title="`${dataField.Label}`"
        ></DocDatePicker>

      </v-col>

      <v-col cols="2">
        <v-text-field
          outlined
          dense
          readonly
          label="Currency"
          v-model="DocCur"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- end of header fields -->

    <!-- start of bp modal -->
    <v-dialog
      v-model="bpDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="accent"
        >
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="bpDialog = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>Select Customer
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="bpDialog = false"
          >
          <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search Customers Using Card Code or Customer Name"
                  ></v-text-field>
                </v-card-title>
                <div v-if="favourites.length>0">
                  <v-card-title>Favourites</v-card-title>
                  <v-data-table
                    @item-selected="clicked"
                    @click:row="removeFromFavourite"
                    :items-per-page="itemsPerPage"
                    v-model="favourites"
                    :headers="bpHeaders"
                    :items="favourites"
                    :single-select="singleSelect"
                    item-key="id"
                    show-select
                    hide-default-footer
                    class="elevation-1"
                  ></v-data-table>
                </div>
                <v-card-title>Customers</v-card-title>                
                <v-data-table
                  @item-selected="clicked"
                  @click:row="addToFavourite"
                  :items-per-page="itemsPerPage"
                  v-model="selected"
                  :headers="bpHeaders"
                  :items="bpMasterData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  hide-default-footer
                  class="elevation-1"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of bp modal-->

    <!-- start favourite modal -->
    <template>
       <div class="text-center">
        <v-dialog
          v-model="favouriteModal"
          width="500"
          transition="dialog-top-transition"
        >
          <v-card class="fav-modal" >
            <header  class="fav-modal-header">{{ favouriteAction }} FAVOURITE</header>
            <div class="fav-modal-body">
              {{ favouriteAction }} {{  favourite.CardName  }}
              {{ favouriteAction =='Add' ? "to  Favourites. This makes it easier to select this customer as favourites appear before searching." : 
                  "from Favourites. You will have to search normally to get this customer next time."}}
            </div>
            <v-card-actions  >
              <v-btn variant="outlined" color="" width="200" @click="favouriteModal = false">Cancel </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary"  class="" width="200" @click="submitToFavourite">{{ favouriteAction }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <!-- end fav modal -->

  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  props: {
    headerFields: {
      type: Array,
    },
    seriesData: {
      type: Array,
    },
    branchesData: {
      type: Array,
    },
    initial: {
      type: Object,
    },
    defaultSeries: {
      type: Object,
      required: true,
    },
    DefaultBPLId: {
      type: String,
    },
    usersData: {
      type: Array,
    },
    deparmentData: {
      type: Array,
    },
    docCreate: {
      type: Boolean,
    },
    docCopyTo: {
      type: Boolean,
    },
    activeObject: {
      type: String,
    },
    defaultRequester: {
      type: Number,
    },
    copyFromHeaderDocumentDetails: {
      type: Object,
    },
  },
  data() {
    return {
      UserInput:[],
      abortController: null,
      cardCodeIsReadOnly: true,
      search: null,
      activeSeries: null,
      dealerDiscount: null,
      docReadonly: "N",
      bpMasterData: [],
      trueValue: true,
      falseValue: false,
      tenant: {},
      // DocNum: "",
      DocCur: "",
      // Requester: null,
      requesterBranch: null,
      selected: [],
      branches: [],
      warehouses: [],
      delears: [],
      paymentTerms: [],
      favourites:[],
      favourite:{},
      favouriteModal:false,
      favouriteAction:"Add",//add|remove
      bpDialog: false,
      itemsPerPage: 100,
      singleSelect: true,
      ReqType: 12,
      Department: null,
      // ohems: [],
      pricelistData: [],
      GroupNum: null,
      SlpCode: null,
      curSources: [
        { name: "Local Currency", value: "L" },
        { name: "System Currency", value: "S" },
        { name: "Bp Currency", value: "C" },
      ],
      status: [
        { name: "Open", value: "O" },
        { name: "Closed", value: "C" },
      ],
      bpHeaders: [
        { text: "Code", value: "CardCode" },
        { text: "Name", value: "CardName" },
      ],
      docTypes: [
        { name: "Items", value: "I" },
        { name: "Service", value: "S" },
      ],

      reqTypes: [
        { name: "User", value: 12 },
        { name: "Employee", value: 171 },
      ],
    };
  },
  watch: {
    UserInput: {
      handler: function(newValues) {
        newValues.forEach((value, i) => {
          const fieldName = this.headerFields[i].FieldName;
          this.setUserInput(fieldName, value);
        })
      },
      deep: true,
    },
    defaultSeries: {
      handler: "setSeries",
      immediate: true,
    },
    DefaultBPLId: {
      handler: "setDefaultBPLId",
      immediate: true,
    },
    tenant: {
      handler: "setTenant",
      immediate: true,
    },
    initial: {
      handler: "setInitial",
      immediate: false,
    },
    GroupNum: {
      handler: "setGroupNum",
      immediate: true,
    },
    search: {
      handler: "getBpMaster",
      immediate: true,
    },
    defaultRequester: {
      handler: "setDefaultRequester",
      immediate: true,
    },
    SlpCode: {
      handler: "emitSlpCode",
      immediate: true,
    },
    "$route.query.c": {
      handler: "passedCustomerFromServiceCalls",
      immediate: true,
    },
    copyFromHeaderDocumentDetails: {
      handler: "setCopyFromHeaderDocumentDetails",
      immediate: true,
    },
  },
  methods: {
    setCopyFromHeaderDocumentDetails(val) {
      if (!this.docCreate) {
        return;
      }
      if (val) {
        this.ReqType = val.ReqType;
        this.BPLId = val.BPLId;
        this.U_SaleType = val.U_SaleType;
        this.U_CashName = val.U_CashName;
        this.U_CashNo = val.U_CashNo;
        this.U_IDNo = val.U_IDNo;
        this.U_CashMail = val.U_CashMail;
        this.U_PCash = val.U_PCash;
        this.NumAtCard2 = val.NumAtCard2;
        this.LicTradNum = val.LicTradNum;
        this.Requester = val.Requester;
        this.Department = val.Department;
        this.NumAtCard = val.NumAtCard;
      }
    },

    passedCustomerFromServiceCalls(val) {
      if (val && this.docCreate) {
        this.$store
          .dispatch("get", `/getCustomers?f=${val}`)
          .then((res) => {
            var searchTenant = res.find((record) => {
              return record.CardCode === val;
            });

            const selectedTenant = searchTenant;
            this.tenant = searchTenant;
            const details = {
              CardCode: selectedTenant.CardCode,
              CardName: selectedTenant.CardName,
            };

            this.LicTradNum = selectedTenant.LicTradNum;

            //DEMO AGRISCOPE REMOVE THIS
            this.GroupNum = selectedTenant.ListNum;

            var d = new Date();
            if (selectedTenant.octg) {
              if (this.activeObject == 13 || this.activeObject == 14) {
                d.setDate(d.getDate() + selectedTenant.octg.ExtraDays);
                var newDate = d.toISOString().substr(0, 10);
                details.DocDueDate = newDate;
              }
            }

            if (this.activeObject == 15) {
              const DocDueDate = d.toISOString().substr(0, 10);
              details.DocDueDate = DocDueDate;
            }
            const original = this.record;
            this.record = { ...original, ...details };
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    setTenant(val) {
      if (val) {
        this.DocCur = val.Currency;
        this.$emit("tenant", val);
      }
    },

    setDefaultBPLId(val) {
      if (val) {
        this.headerFields.forEach((header, i)=>{
          if (header.FieldName == "BPLId"){
            this.UserInput[i] = val;
          }
        });
      //   if (this.record.id == null) {
      //     console.log('setting default branch')
      //     console.log(val)
      //     // this.setSelectedBranch(val);
      //     this.BPLId = BPLId;
      //   }
        if (this.getGlobalWarehouses[val]){
          this.warehouses = this.getGlobalWarehouses[val]
        }else {
          this.$store
              .dispatch("get", `/warehouse?branchID=${val}`)
              .then((res) => {
                var newGlobalWarehouse = { ...this.getGlobalWarehouses };
                newGlobalWarehouse[val] = res;
                this.$store.commit("globalWarehouses/loadData", newGlobalWarehouse);
                this.warehouses = res;
              })
              .catch((err) => {
                console.log(err);
              });
        }
      }
    },

    setDefaultRequester(val) {
      if (val) {
        this.Requester = val;
      }
    },

    setInitial(val) {
      if (val) {
        this.headerFields.forEach((header, i)=>{
          const fName = header.FieldName;
          if (val.hasOwnProperty(fName)) {
            this.UserInput[i] = val[fName];
          }
        });
        if (val.ExtRef) {
          this.cardCodeIsReadOnly = true;
        }
        this.ReqType = val.ReqType;
        this.tenant = val.business_partner;
        // this.BPLId = val.BPLId;
        // this.NumAtCard2 = val.NumAtCard2;
        // this.LicTradNum = val.LicTradNum;
        this.Requester = val.Requester;
        this.Department = val.Department;
        // this.NumAtCard = val.NumAtCard;
        // this.CardCode = val.CardCode;
        if (!this.docCreate && !this.docCopyTo) {
          this.DocNum = val.DocNum;
          this.Series = val.Series;
        }
        if (this.docCopyTo) {
          this.setDocNumber(this.record.series);
        }

        // this.record = { ...val };
      }
    },

    emitSlpCode(val) {
      this.$emit("SlpCode", val);
    },

    setDate(data) {
      const self = this;
      const fieldName = data.fieldName;

      if (self.activeObject == 13 || self.activeObject == 14) {
        if (fieldName === "DocDate" && this.tenant.octg !== undefined) {
          const ExtraDays = this.tenant.octg.ExtraDays ?? 0;
          var d = new Date(data.date);

          d.setDate(d.getDate() + ExtraDays);
          const DocDueDate = d.toISOString().substr(0, 10);
          const details = {
            DocDueDate: DocDueDate,
          };

          const original = this.record;
          this.record = { ...original, ...details };
        }

        if (
          fieldName === "DocDueDate" &&
          this.docCopyTo &&
          this.tenant.octg !== undefined
        ) {
          const ExtraDays = this.tenant.octg.ExtraDays ?? 0;
          var d2 = new Date(this.record.DocDate);

          d2.setDate(d2.getDate() + ExtraDays);
          const DocDueDate = d2.toISOString().substr(0, 10);
          const details = {
            DocDueDate: DocDueDate,
          };

          const original = this.record;
          this.record = { ...original, ...details };
        }
        return;
      }

      this.record = { ...this.record, [data.fieldName]: data.date };
    },

    setGroupNum(val) {
      this.$emit("setGroupNum", val);
    },

    setSeries(val) {
      if (val) {
        if (val.NextNumber) {
          this.headerFields.forEach((header, i)=>{
            if (header.FieldName == "Series"){
              this.UserInput[i] = val.id;
            }
            if (header.FieldName == "DocNum"){
              this.UserInput[i] = val.NextNumber;
            }
          });
          // this.record.activeSeries = val.id;
        }
        if (val.IsManual == "Y") {
          this.docReadonly = "N";
        } else {
          this.docReadonly = "Y";
        }
      }
    },

    openBp() {
      if (this.docCreate) {
        this.selected = [];
        this.bpDialog = true;
      }
      this.loadFavourites()
    },

    getData(data) {
      return this.$data[data];
    },
    loadFavourites(){
      const favs=localStorage.getItem('favourites');
      if(favs==undefined){
        localStorage.setItem("favourites",JSON.stringify([]))
        return;
      }else{
        this.favourites=JSON.parse(favs)
      }
    },

    addToFavourite(item){
      const allowAdd=JSON.parse(localStorage.getItem('stock')).user.defaults.AddToFavourites;
      if(allowAdd===1){
        this.favouriteAction="Add";
        this.favourite=item
        this.favouriteModal=true
        this.$refs.snackbar.show("Added to favourites ", "red");
      }
    },

    removeFromFavourite(item){
      this.favouriteAction="Remove";
      this.favourite=item;
      this.favouriteModal=true;
    },

    submitToFavourite(){
      if(this.favouriteAction=='Add'){
        this.favourites.push(this.favourite);
        localStorage.setItem("favourites",JSON.stringify(this.favourites))
      }else {
        this.favourites=this.favourites.filter((itm)=>itm !== this.favourite)
        localStorage.setItem("favourites",JSON.stringify(this.favourites))
      }
      this.favouriteModal=false;
    },

    clicked($event) {
      this.tenant = $event.item;
      const selectedTenant = $event.item;


      this.headerFields.forEach((header, i)=>{
        if (header.FieldName == "CardCode"){
          this.UserInput[i] = selectedTenant.CardCode;
        }
        if (header.FieldName == "CardName"){
          this.UserInput[i] = selectedTenant.CardName;
        }
        if (header.FieldName == "LicTradNum"){
          this.UserInput[i] = selectedTenant.LicTradNum;
        }
        if (header.FieldName == "DocDueDate"){
          var d = new Date();
          const DocDueDate = d.toISOString().substr(0, 10);
          if (selectedTenant.octg) {
            if (this.activeObject == 13 || this.activeObject == 14) {
              d.setDate(d.getDate() + selectedTenant.octg.ExtraDays);
              var newDate = d.toISOString().substr(0, 10);
              this.UserInput[i] = newDate;
            }
          } else {
            this.UserInput[i] = DocDueDate;
          }
          if (this.activeObject == 15) {
            this.UserInput[i] = DocDueDate;
          }
        }
      });
      this.bpDialog = false;
    },

    setDocNumber(id) {
      var seriesRecord = this.seriesData.find((record) => {
        return record.id === id;
      });

      if (this.docCopyTo) {
        seriesRecord = this.seriesData.find((record) => {
          return record.SeriesName === this.BPLId;
        });

        this.setSeries(seriesRecord);

        return;
      }

      if (seriesRecord.IsManual === "Y") {
        this.docReadonly = "N";
        this.record = { ...this.record, DocNum: null };
      } else {
        this.docReadonly = "Y";
        this.DocNum = seriesRecord.NextNumber;
        this.record = { ...this.record, DocNum: seriesRecord.NextNumber };
      }
      this.activeSeries = seriesRecord.id;
    },

    setDefaults() {
      const date = new Date().toISOString().substr(0, 10);
      const details = {
        DocDate: date,
        TaxDate: date,
        CurSource: "L",
        DocStatus: "Open",
      };

      if (this.docCreate && this.activeObject == 205) {
        details.DocDueDate = date;
      }

      this.record = { ...details };
    },

    getBpMaster() {
      const self = this;
      const searchTerm = this.search

      if (!this.localSettings.enable) {
        this.$store
            .dispatch("get", `/getCustomers?f=${searchTerm}`)
            .then((res) => {
              if (searchTerm == this.search){
                self.bpMasterData = res;
              }
            })
            .catch((err) => {
              console.log(err);
            });

      }
      if (this.localSettings.enable) {
        self.getBpMasterLocal();
      }

    },

    getBpMasterLocal() {
      const self = this;
      if (self.Loading) return;
      self.loading = true;
      self.bpMasterData = [];
      const url = self.localSettings.localurl + "/o_c_r_d_s?codenamesearch=" + this.search;
      axios.get(url, this.options).then((resp) => {
        this.loading = false;
        self.bpMasterData = resp.data;
      });
    },

    getDealers() {
      this.$store
        .dispatch("get", `/dealer_masterdata`)
        .then((res) => {
          this.delears = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    dealerSelected(data) {
      const selectedDealer = this.delears.find((record) => {
        return record.Name === data;
      });
      if (selectedDealer.Discount > 0) {
        this.dealerDiscount = selectedDealer.Discount;
        this.$emit("setDealerDiscount", this.dealerDiscount);
      }

      this.U_CashNo = selectedDealer.Phone;
      this.record = { ...this.record, U_CashNo: selectedDealer.Phone };
    },

    autoPopulateBranch(val) {
      const self = this;
      this.requesterBranch = "";
      if (self.ReqType == 12) {
        const userDetails = self.usersData.find((record) => {
          return record.id === val;
        });
        self.Department = userDetails.Department;
        self.requesterBranch = userDetails.oudg.branch.ExtRef;
      }
      if (self.ReqType == 171) {
        const employee = self.ohems.find((record) => {
          return record.id === val;
        });

        self.requesterBranch = employee.branch;
        self.Department = parseInt(employee.dept);
      }
    },

    getPriceList() {
      const self = this;
      if (self.priceList){
        self.pricelistData = self.priceList;
      }else{
        this.$store
            .dispatch("get", `/price_lists`)
            .then((res) => {
              self.pricelistData = res.ResponseData;
              self.$store.commit("setPriceList",res.ResponseData)
            })
            .catch((err) => {
              this.$refs.snackbar.show(err, "red");
            });
      }

    },

    setUserInput(fieldName,val) {
      this.$emit("setUserInput", {val, fieldName});
    },
  },

  computed: {
    ...mapGetters(
        {
          ohem: "ohem/ohems",
          getGlobalWarehouses:"globalWarehouses/getGlobalWarehouses",
          priceList:"getPriceList",
          localSettings:"getLocalSettings",
        }
    )
  },
  created() {
    // this.getEmployees();
    this.setDefaults();
    //  this.getBpMaster();
    this.getPriceList();
  },
};
</script>

<style scoped>
  .fav-modal{
   padding: 20px; 
  }
  .fav-modal-header{
    background-color: white;
    text-align: center;
    font-weight: bold;
    font-size: larger;
    text-transform: uppercase;
  }

  .fav-modal-body{
    font-weight: normal;
    font-size: large;
    padding: 20px;
    padding-top: 30px;
  }


</style>
