<template>
  <v-form ref="form">
    <v-card>
      <!-- start of toolbar 1 -->
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <span v-show="docCreate">Create</span>
          {{ objectName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn color="primary" outlined small text @click="goBack">
          <v-icon left dark>mdi-skip-backward</v-icon>Back
        </v-btn>
        <v-btn
          color="red ml-2"
          outlined
          small
          text
          @click="openClosePageConfirmationDialog"
        >
          <v-icon light>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- end of toolbar 1 -->

      <!-- start of toolbar 2 -->
      <v-toolbar color="secondary" flat dense>
        <v-btn
          v-if="this.localSettings.enable"
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          @click="postData"
          :loading="$store.state.loader"
        >
          <v-icon left dark>mdi-upload</v-icon>Post
        </v-btn>

        <v-btn
          v-else
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          @click="sendData"
          :loading="$store.state.loader"
        >
          <v-icon left dark>mdi-upload</v-icon>Save
        </v-btn>

        <v-spacer></v-spacer>

        <v-col cols="2">
          <sale-report v-show="this.initialRecord.printUnsyncDocs != 1"></sale-report>
        </v-col>
      </v-toolbar>
      <!-- end of toolbar 2 -->

      <v-card-text>
        <!-- header section -->
        <!-- <header-editor
          @tenant="setTenantData"
          @selectedSeries="setSeries"
          @selectedBranch="setBranch"
          @selectedRequester="setRequester"
          @selectedSaleType="setSaleType"
          @selectedDealer="setDealer"
          @selectedReqType="setReqType"
          @selectedDepartment="setDepartment"
          @headerRecord="setHeaderRecord"
          @setDealerDiscount="setDealerDiscount"
          @selectedPettyCash="setPettyCash"
          @setLicTradNum="setLicTradNum"
          @setU_CashName="setU_CashName"
          @setU_CashNo="setU_CashNo"
          @setU_IDNo="setU_IDNo"
          @setNumAtCard="setNumAtCard"
          @setGroupNum="setGroupNum"
          :defaultSeries="defaultSeries"
          :DefaultBPLId="DefaultBPLId"
          :defaultRequester="defaultRequester"
          :headerFields="setup.HeaderFields"
          :seriesData="seriesData"
          :branchesData="branchesData"
          :usersData="usersData"
          :deparmentData="deparmentData"
          :docCreate="docCreate"
          :docCopyTo="docCopyTo"
          :activeObject="activeObject"
          :copyFromHeaderDocumentDetails="copyFromHeaderDocumentDetails"
          :initial="initialRecord"
        ></header-editor> -->
        <!-- end of header section -->

        <!-- start of tabs -->
        <v-row v-if="setup.tabs">
          <v-col cols="12">
            <v-tabs background-color="accent" color="white">
              <!-- loop tabs -->
              <v-tab v-for="(tab, index) in setup.tabs" v-bind:key="index">{{
                tab.Label
              }}</v-tab>
              <!-- loop tab items -->

              <v-tab-item>
                <!-- header section -->
                <header-editor
                  @setUserInput="setUserInput"
                  @tenant="setTenantData"
                  @setDealerDiscount="setDealerDiscount"
                  @setGroupNum="setGroupNum"
                  @SlpCode="setSlpCode"
                  :defaultSeries="defaultSeries"
                  :DefaultBPLId="DefaultBPLId"
                  :defaultRequester="defaultRequester"
                  :headerFields="setup.HeaderFields"
                  :seriesData="seriesData"
                  :branchesData="branchesData"
                  :usersData="usersData"
                  :deparmentData="deparmentData"
                  :docCreate="docCreate"
                  :docCopyTo="docCopyTo"
                  :activeObject="activeObject"
                  :copyFromHeaderDocumentDetails="copyFromHeaderDocumentDetails"
                  :initial="initialRecord"
                ></header-editor>
                <!-- end of header section -->

                <!-- start of footer editor -->
                <footer-editor
                  @setUserInput="setUserInput"
                  @discount="setGenDiscount"
                  @salePaymentData="setPaymentData"
                  :docCreate="docCreate"
                  :ExtRefDocNum="currentExtRefDocNum"
                  :ExtRef="currentExtRef"
                  :DefaultSlp="DefaultSlp"
                  :salesEmployees="salesEmployees"
                  :docTotal="docTotal"
                  :genDiscount="genDiscount"
                  :dealerDiscount="dealerDiscount"
                  :discountAmount="discountAmount"
                  :genTotalTax="genTotalTax"
                  :footerFields="setup.FooterFields"
                  :finalTotalAmount="finalTotalAmount"
                  :finalRoundDif="finalRoundDif"
                  :withPayment="withPayment"
                  :activeObject="activeObject"
                  :copyFromHeaderDocumentDetails="copyFromHeaderDocumentDetails"
                  :initial="initialRecord"
                ></footer-editor>
                <!-- end of footer editor -->
              </v-tab-item>

              <v-tab-item v-for="(tab, index) in setup.tabs" v-bind:key="index">
                <!-- tab editor -->
                <tab-editor
                  :tab="tab"
                  :selectedTenant="selectedTenant"
                  :NewBPLId="NewBPLId"
                  @saleItemsData="setSaleData"
                  @setUserFields="setUserFields"
                  @attachments="setAttchments"
                  @copyFromHeaderDocumentDetails="setCopyFromHeaderDocumentDetails"
                  :docCreate="docCreate"
                  :UDFs="setup.UDFs.HeaderUserFields"
                  :docCopyTo="docCopyTo"
                  :initial="initialRecord"
                  :activeObject="activeObject"
                  :dealerDiscount="dealerDiscount"
                  :initialRows="tableData"
                  :GroupNum="GroupNum"
                ></tab-editor>
                <!-- end of tab editor -->
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <!-- End of tabs -->

        <!-- start of journal entry modal -->
        <v-dialog
          v-model="journalDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="accent">
              <v-toolbar-title>
                <v-btn icon dark @click="journalDialog = false">
                  <v-icon dark>mdi-keyboard-backspace</v-icon> </v-btn
                >Journal Entry
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="journalDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card>
        </v-dialog>
        <!-- end of journal entry modal-->

        <!-- closePageConfirmation -->
        <v-dialog v-model="closePageConfirmationDialog" max-width="650px">
          <v-card>
            <v-toolbar dense color="red" dark>
              <v-toolbar-title>Confirmation</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.native="closePageConfirmationDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    If you leave, your unsaved changes will be lost.continue?
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="red" dark @click="closePageAndRedirectAction"
                      >Yes</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- closePageConfirmation-->

        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import HeaderEditor from "../_components/header-editor.vue";
import FooterEditor from "../_components/footer-editor.vue";
import TabEditor from "../_components/tab-editor.vue";
import SaleReport from "../../../reports/sales-report.vue";
import { addItem } from "../methods/addItems.js";

import { mapGetters } from "vuex";

export default {
  components: {
    HeaderEditor,
    FooterEditor,
    TabEditor,
    SaleReport,
  },
  props: {
    originNo: {
      type: String,
    },
    originName: {
      type: String,
    },
    docCreate: {
      type: Boolean,
    },
    docCopyTo: {
      type: Boolean,
    },
    objectName: {
      type: String,
    },
    activeObject: {
      type: String,
    },
    setup: {
      type: Object,
      required: true,
    },
    initial: {
      type: Object,
      required: true,
    },
    seriesData: {
      type: Array,
      required: true,
    },
    branchesData: {
      type: Array,
      required: true,
    },
    defaultSeries: {
      type: Object,
      required: true,
    },
    DefaultBPLId: {
      type: String,
    },
    tableData: {
      type: Array,
    },
    usersData: {
      type: Array,
    },
    deparmentData: {
      type: Array,
    },
    withPayment: {
      type: String,
    },
  },
  computed: {
    discountAmount() {
      let discount = 0;
      if (this.genDiscount) {
        let discPerct = parseInt(this.genDiscount);
        if (discPerct > 0) {
          discount = (discPerct / 100) * this.docTotal;
        }
      }
      return discount;
    },

    docTotal() {
      let total = 0;
      if (typeof this.saleItemsData != "undefined" && this.saleItemsData.length > 0) {
        return this.saleItemsData
          .map((item) => {
            const amount = item.LineTotal;

            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },

    totalTax() {
      let total = 0;
      if (typeof this.saleItemsData != "undefined" && this.saleItemsData.length > 0) {
        return this.saleItemsData
          .map((item) => {
            const amount = item.VatSum;
            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },

    genTotalTax() {
      let total = 0;
      let totalTax = this.totalTax;
      let discount = 0;
      if (this.genDiscount) {
        discount = parseFloat(this.genDiscount);
        total = totalTax - (discount / 100) * totalTax;
      } else {
        total = totalTax;
      }
      return parseFloat(total.toFixed(2));
    },

    genTotalAmount() {
      let total = 0;
      let docTotal = this.docTotal;
      let discount = 0;
      if (this.genDiscount) {
        discount = parseFloat(this.genDiscount);
        total = docTotal - (discount / 100) * docTotal;
      } else {
        total = docTotal;
      }
      return parseFloat(total.toFixed(2));
    },

    finalTotalAmount() {
      var docTotalAmount = this.genTotalTax + this.genTotalAmount;
      var roundedTotal = Number(Math.round(docTotalAmount));

      return roundedTotal.toFixed(2);
    },

    finalRoundDif() {
      var docTotalAmount = this.genTotalTax + this.genTotalAmount;
      var roundedTotal = Number(Math.round(docTotalAmount));
      return (roundedTotal - docTotalAmount).toFixed(2);
    },

    currentExtRefDocNum() {
      return this.record.ExtRefDocNum;
    },

    currentExtRef() {
      return this.record.ExtRef;
    },

    defaultRequester() {
      return this.$store.state.user.id;
    },
    ...mapGetters({
      getGlobalSalesEmployees: "salesEmployee/salesEmployees",
      getDefaultSettings: "getDefaultSettings",
      getGlobalDealers: "dealers/getDealers",
      localSettings: "getLocalSettings",
    }),
  },
  data() {
    return {
      dialog: false,
      loader: false,
      draftLoader: false,
      manualNum: false,
      DefaultSlp: null,
      initialRecord: {},
      search: null,
      SlpCode: null,
      NewBPLId: null,
      selectedSeries: null,
      SeriesObject: {},
      searchItem: null,
      activeSeries: null,
      itemDialog: false,
      selectedItems: [],
      itemsPerPage: 20,
      uploadedFiles: {},
      genDiscount: null,
      record: {},
      dialogSetup: [],
      singleSelect: true,
      selectedDocs: [],
      selectedTenant: {},
      saleItemsData: [],
      salesEmployees: [],
      docId: null,
      copyDocs: [],
      trueValue: true,
      falseValue: false,
      journalDialog: false,
      dealerDiscount: null,
      copyFromHeaderDocumentDetails: {},
      RoundDif: null,
      printUnsyncDocs: null,
      defaultSettings: {},
      closePageConfirmationDialog: false,
      GroupNum: null,
    };
  },
  watch: {
    tableData: {
      handler: "setTableData",
      deep: true,
      immediate: true,
    },
    initial: {
      handler: "setInitialRecord",
      immediate: true,
    },
    withPayment: {
      handler: "setWithPayment",
      immediate: true,
    },
  },
  methods: {
    addItem,
    viewJournalEntry() {
      this.journalDialog = true;
    },
    setAttchments(data) {
      this.uploadedFiles = data;
    },

    setUserInput(data) {
      if (data.fieldName == "BPLId") {
        this.NewBPLId = data;
        //get global dealers
        if (this.getGlobalDealers[data.val]) {
          this.delears = this.getGlobalDealers[data.val];
        } else {
          this.$store
            .dispatch("get", `/dealer_masterdata?branchID=${data.val}`)
            .then((res) => {
              this.delears = res.ResponseData;
              var dealersData = { ...this.getGlobalDealers };
              dealersData[data.val] = res.ResponseData;
              this.$store.commit("dealers/loadData", dealersData);
            })
            .catch((err) => {
              this.$refs.snackbar.show(err, "red");
            });
        }
      }
      this.record = { ...this.record, [data.fieldName]: data.val };
    },
    setGenDiscount(data) {
      this.genDiscount = parseInt(data);
    },
    setGroupNum(data) {
      if (data) {
        this.GroupNum = data;
      }
    },
    setSaleData(data) {
      if (data.length == 0) {
        return;
      }
      // console.log(data, 'data');
      // console.log(this.saleItemsData, 'sale items');
      this.saleItemsData = [...data];
      // console.log(this.saleItemsData, 'update items')
      // this.saleItemsData = data;
    },
    setTenantData(data) {
      this.selectedTenant = data;
    },
    setDealerDiscount(data) {
      this.dealerDiscount = data;
    },
    setCopyFromHeaderDocumentDetails(data) {
      if (data) {
        this.copyFromHeaderDocumentDetails = data;
      }
    },
    setSlpCode(data) {
      if (data) {
        this.DefaultSlp = data;
      }
    },
    getData(data) {
      return this.$data[data];
    },
    setTableData(val) {
      this.saleItemsData = val;
    },
    setWithPayment(val) {
      const details = { IsICT: val };
      this.record = { ...this.record, ...details };
    },
    setUserFields(val) {
      if (val) {
        this.record.UserFields = {
          ...this.record.UserFields,
          [val.fieldName]: val.val,
        };
      }
      if (!val) {
        const Udfs = this.setup.UDFs;
        const transformedObject = {};

        Udfs.HeaderUserFields.forEach((obj) => {
          transformedObject[obj.FieldName] = obj;
        });

        this.record.UserFields = Udfs.HeaderUserFields;
        console.log(this.record.UserFields);
      }
    },
    setPaymentData(data) {
      const details = [data];
      this.record = { ...this.record, payments: details };

      // const details = { payment: data };
      // this.record = { ...this.record, ...details };
    },

    setInitialRecord(val) {
      if (val) {
        this.record = { ...val };
        this.initialRecord = { ...val };
      }
    },
    sendData() {
      const data = this.record;
      data.SeriesObject = this.seriesData.find((record) => {
        return record.id === data.Series;
      });
      data.DocTotal = this.finalTotalAmount;
      data.VatSum = this.genTotalTax;
      data.DiscPrcnt = this.genDiscount || 0;
      data.DiscSum = this.discountAmount || 0;
      data.document_lines = this.saleItemsData;
      data.oats = this.uploadedFiles;
      this.$store.commit("loader", true);
      this.$emit("data", data);
    },
    postData() {
      const data = this.record;
      data.SeriesObject = this.seriesData.find((record) => {
        return record.id === data.Series;
      });
      data.DocTotal = this.finalTotalAmount;
      data.VatSum = this.genTotalTax;
      data.DiscPrcnt = this.genDiscount || 0;
      data.DiscSum = this.discountAmount || 0;
      data.document_lines = this.saleItemsData;
      data.oats = this.uploadedFiles;
      this.$store.commit("loader", true);
      this.$emit("postData", data);
    },
    openItemModal() {
      this.selectedItems = [];
      this.itemDialog = true;
    },
    openModal(data) {
      this.dialogSetup = data;
      this.dialog = true;
    },
    closeModal(closeStatus) {
      this.dialog = closeStatus;
    },
    setDate() {},
    save() {
      this.$refs.snackbar.show("Data saved", "green");
    },
    cancel() {
      this.$refs.snackbar.show("Cancelled", "green");
    },
    open() {},
    close() {},
    getSalesEmployees() {
      const self = this;
      if (self.getGlobalSalesEmployees) {
        self.salesEmployees = self.getGlobalSalesEmployees;
      } else {
        this.$store
          .dispatch("get", `/employee`)
          .then((res) => {
            self.$store.commit("salesEmployee/loadData", res.ResponseData);
            self.salesEmployees = res.ResponseData;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
      }
    },
    generalSettings() {
      const self = this;
      if (self.getDefaultSettings) {
        const default_settings = self.getDefaultSettings;
        self.defaultSettings = default_settings;
        if (default_settings.DfltSlp !== null) {
          self.DefaultSlp = default_settings.DfltSlp;
        }
      } else {
        self.$store
          .dispatch("get", `/general_settings`)
          .then((res) => {
            self.defaultSettings = res.ResponseData;
            self.$store.commit("setDefaultSettings", res.ResponseData);
            // set default slp
            if (res.ResponseData.DfltSlp !== null) {
              self.DefaultSlp = res.ResponseData.DfltSlp;
            }
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
      }
    },
    closePageAndRedirectAction() {
      this.$router.push(`/sales/doc/${this.activeObject}`);
    },
    openClosePageConfirmationDialog() {
      if (this.docCreate) {
        this.closePageConfirmationDialog = true;
      } else {
        this.$router.push(`/sales/doc/${this.activeObject}`);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.getSalesEmployees();
    this.generalSettings();
    this.setUserFields();
  },
};
</script>
