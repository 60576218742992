<template>
  <v-container fluid id="docForm">
    <!-- with table -->
    <v-row v-if="tab.WithTable == 1">
      <v-container fluid id="tableDivContainer">
        <!-- start of new item button -->

        <v-row>
          <v-col cols="4">
            <v-autocomplete
              outlined
              dense
              v-model="DocType"
              :items="docTypes"
              item-text="name"
              item-value="value"
              label="Doc Type"
              @change="setDocType"
            ></v-autocomplete>
          </v-col>
          <!-- <v-col cols="4">

            <v-autocomplete
              v-model="model"
              :items="entries"
              :loading="loading"
              :search-input.sync="search"
              hide-no-data
              hide-selected
              no-filter
              dense
              outlined
              item-text="ItemName"
              item-value="API"
              label="Item Code"
              style="width: 300px;"
              :debounce-search="0"
              return-object
            ></v-autocomplete>
          </v-col> -->
          <v-col cols="2">
            <v-btn icon @click="openItemSerialModal">
              <v-icon>mdi-watermark</v-icon>
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-btn icon @click="pasteExcel"> Paste From Excel </v-btn>
          </v-col>

          <v-col cols="2">
            <v-autocomplete
              dense
              outlined
              @change="copyDocFrom(copyDocFromObject)"
              color="primary"
              v-model="copyDocFromObject"
              :items="copyFromDocs"
              item-text="name"
              item-value="id"
              label="Copy From"
              v-show="docCreate"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row id="tableDataDiv">
          <v-col cols="12">
            <v-data-table
              light
              show-select
              hide-default-footer
              fixed-header
              v-model="selectedDocs"
              item-key="LineNum"
              :items-per-page="1000"
              :single-select="singleSelect"
              :headers="visibleRows(tableRows)"
              :items="saleItemsData"
              id="salesItemData"
            >
              <!-- item code -->
              <template v-slot:item.ItemCode="{ item }">
                <v-text-field
                  single-line
                  style="width: 130px"
                  outlined
                  dense
                  v-model="item.ItemCode"
                  :append-icon="'mdi-airballoon'"
                  @click:append="openItemModal(item)"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Description -->
              <template v-slot:item.Dscription="{ item }">
                <v-text-field
                  single-line
                  outlined
                  dense
                  v-model="item.Dscription"
                  style="width: 300px"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Warehouse Code -->
              <template v-slot:item.AcctCode="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.AcctCode"
                  label="G/L Account"
                  :items="glAccounts"
                  :item-text="(item) => item.AcctCode + '  -  ' + item.AcctName"
                  item-value="AcctCode"
                  @change="inputChanged(item)"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Quantity -->
              <template v-slot:item.Quantity="{ item }">
                <v-text-field
                  @input="inputChanged(item)"
                  single-line
                  outlined
                  dense
                  v-model="item.Quantity"
                  style="width: 100px"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Stock In warehouse -->
              <!--              <template v-slot:item.U_StockWhse="{ item }">-->
              <!--                <v-text-field-->
              <!--                  readonly-->
              <!--                  single-line-->
              <!--                  outlined-->
              <!--                  filled-->
              <!--                  dense-->
              <!--                  v-model="item.U_StockWhse"-->
              <!--                  style="width: 100px;"-->
              <!--                ></v-text-field>-->
              <!--              </template>-->
              <!-- end -->

              <!-- Stock In warehouse -->
              <!--              <template v-slot:item.U_AllowDisc="{ item }">-->
              <!--                <v-text-field-->
              <!--                  readonly-->
              <!--                  single-line-->
              <!--                  outlined-->
              <!--                  dense-->
              <!--                  filled-->
              <!--                  v-model="item.U_AllowDisc"-->
              <!--                  style="width: 100px;"-->
              <!--                ></v-text-field>-->
              <!--              </template>-->
              <!-- end -->

              <!-- Stock In warehouse -->
              <!--              <template v-slot:item.U_ItemCost="{ item }">-->
              <!--                <v-text-field-->
              <!--                  readonly-->
              <!--                  single-line-->
              <!--                  outlined-->
              <!--                  dense-->
              <!--                  v-model="item.U_ItemCost"-->
              <!--                  style="width: 100px;"-->
              <!--                ></v-text-field>-->
              <!--              </template>-->
              <!-- end -->

              <!-- Unit Price -->
              <template v-slot:item.PriceBefDi="{ item }">
                <div class="card d-flex flex-row me-3 shadow">
                  <v-text-field
                    type="number"
                    @input="inputChanged(item)"
                    :readonly="unitPriceIsReadonly"
                    single-line
                    outlined
                    dense
                    v-model="item.PriceBefDi"
                    style="width: 130px"
                  ></v-text-field>
                  <!-- add calculator -->
                  <v-icon
                    @click="
                      selectedRow = item;
                      calculatePriceModal = true;
                    "
                    >mdi-calculator-variant</v-icon
                  >
                </div>
              </template>
              <!-- end -->

              <!-- Discount -->
              <template v-slot:item.DiscPrcnt="{ item }">
                <v-text-field
                  type="number"
                  @input="inputChanged(item)"
                  single-line
                  outlined
                  dense
                  v-model="item.DiscPrcnt"
                  style="width: 100px"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Currency Rate -->
              <template v-slot:item.Rate="{ item }">
                <v-text-field
                  style="width: 150px"
                  single-line
                  outlined
                  dense
                  v-model="item.Rate"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Price after disccount -->
              <template v-slot:item.Price="{ item }">
                <v-text-field
                  readonly
                  filled
                  single-line
                  outlined
                  dense
                  v-model="item.Price"
                  style="width: 150px"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- tax Code -->
              <template v-slot:item.TaxCode="{ item }">
                <v-autocomplete
                  dense
                  outlined
                  v-model="item.TaxCode"
                  :items="taxGroups"
                  :item-text="(item) => item.code + '  -  ' + item.name"
                  item-value="code"
                  @change="inputChanged(item)"
                  style="width: 150px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Gross price -->
              <template v-slot:item.PriceAfVAT="{ item }">
                <v-text-field
                  style="width: 150px"
                  readonly
                  filled
                  single-line
                  outlined
                  dense
                  v-model="item.PriceAfVAT"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Total LC -->
              <template v-slot:item.LineTotal="{ item }">
                <v-text-field
                  style="width: 150px"
                  :readonly="lineTotalIsReadonly"
                  filled
                  single-line
                  outlined
                  v-model="item.LineTotal"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Warehouse Code -->
              <template v-slot:item.WhsCode="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.WhsCode"
                  label="Whse 1212"
                  :items="warehouses"
                  item-value="WhsCode"
                  :item-text="(item) => item.WhsCode + '  -  ' + item.WhsName"
                  @change="warehouseChanged(item)"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Total LC -->
              <template v-slot:item.WhsName="{ item }">
                <v-text-field
                  style="width: 150px"
                  readonly
                  filled
                  single-line
                  outlined
                  dense
                  v-model="item.WhsName"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Total Tax Row -->
              <template v-slot:item.VatSum="{ item }">
                <v-text-field
                  readonly
                  filled
                  single-line
                  outlined
                  dense
                  v-model="item.VatSum"
                  style="width: 150px"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Gross Profit -->
              <template v-slot:item.GrssProfit="{ item }">
                <v-text-field
                  readonly
                  filled
                  single-line
                  outlined
                  dense
                  v-model="item.GrssProfit"
                  style="width: 150px"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Uom Code -->
              <template v-slot:item.UomCode="{ item }">
                <v-text-field
                  v-if="item.IsManual && item.IsManual == 'Y'"
                  readonly
                  filled
                  single-line
                  outlined
                  dense
                  v-model="ManualValue"
                  style="width: 150px"
                ></v-text-field>

                <v-autocomplete
                  v-else
                  single-line
                  dense
                  outlined
                  v-model="item.UomCode"
                  label="Uom Code"
                  :items="item.uoms"
                  item-text="uomentry.UomName"
                  item-value="uomentry.id"
                  @change="changedUom(item)"
                  style="width: 150px"
                ></v-autocomplete>
              </template>

              <!-- end -->

              <!-- Uom Name -->
              <!-- <template v-slot:item.UnitMsr="{ item }">
            <v-text-field
              readonly
              filled
              single-line

              outlined
              dense
              v-model="item.UnitMsr"
            ></v-text-field>
          </template>-->
              <!-- end -->

              <!-- Gross Total-->
              <template v-slot:item.GTotal="{ item }">
                <v-text-field
                  readonly
                  filled
                  single-line
                  outlined
                  dense
                  v-model="item.GTotal"
                  style="width: 150px"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Cost Center Code -->
              <template v-slot:item.OcrCode="{ item }">
                <!-- {{distributionRules1}} -->
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.OcrCode"
                  :items="distributionRules1"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Cost Center Code -->
              <template v-slot:item.OcrCode2="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.OcrCode2"
                  :items="distributionRules2"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  @change="changedCostCenter(item)"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Cost Center Code -->
              <template v-slot:item.OcrCode3="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.OcrCode3"
                  :items="distributionRules3"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  @change="changedCostCenter(item)"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Cost Center Code -->
              <template v-slot:item.OcrCode4="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.OcrCode4"
                  :items="distributionRules4"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  @change="changedCostCenter(item)"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Cost Center Code -->
              <template v-slot:item.OcrCode5="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.OcrCode5"
                  :items="distributionRules5"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  @change="changedCostCenter(item)"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Cost Center Code -->
              <template v-slot:item.CogsOcrCod="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.CogsOcrCod"
                  :items="distributionRules1"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  style="width: 190px"
                ></v-autocomplete>
              </template>

              <!-- Cost Center Code -->
              <template v-slot:item.CogsOcrCo2="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.CogsOcrCo2"
                  :items="distributionRules2"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  style="width: 190px"
                ></v-autocomplete>
              </template>

              <!-- Cost Center Code -->
              <template v-slot:item.CogsOcrCo3="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.CogsOcrCo3"
                  :items="distributionRules3"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  style="width: 190px"
                ></v-autocomplete>
              </template>

              <!-- Cost Center Code -->
              <template v-slot:item.CogsOcrCo4="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.CogsOcrCo4"
                  :items="distributionRules4"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  style="width: 190px"
                ></v-autocomplete>
              </template>

              <!-- Cost Center Code -->
              <template v-slot:item.CogsOcrCo5="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.CogsOcrCo5"
                  :items="distributionRules5"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  style="width: 190px"
                ></v-autocomplete>
              </template>

              <!-- Cost Center Code -->
              <!--              <template v-slot:item.U_Promotion="{ item }">-->
              <!--                <v-autocomplete-->
              <!--                  single-line-->
              <!--                  dense-->
              <!--                  outlined-->
              <!--                  v-model="item.U_Promotion"-->
              <!--                  :items="promototionsTypes"-->
              <!--                  item-text="name"-->
              <!--                  item-value="value"-->
              <!--                  style="width: 190px;"-->
              <!--                ></v-autocomplete>-->
              <!--              </template>-->

              <!-- Without Stock Adjacemtn -->
              <template v-slot:item.NoInvtryMv="{ item }">
                <v-checkbox
                  single-line
                  dense
                  outlined
                  v-model="item.NoInvtryMv"
                  item-text="name"
                  item-value="value"
                  style="width: 190px"
                  true-value="Y"
                  false-value="N"
                ></v-checkbox>
              </template>

              <!-- end -->
            </v-data-table>
          </v-col>

          <!-- end of new item button -->
        </v-row>

        <v-row>
          <v-col cols="6 mt-1">
            <v-autocomplete
              v-model="selectedItemCode"
              :items="entries"
              :loading="loading"
              :search-input.sync="search"
              hide-no-data
              no-filter
              dense
              outlined
              item-text="ItemDesc"
              item-value="ItemCode"
              label="Search Item using Item Code"
              style="width: 500px"
              v-if="showSearchItemInput"
            ></v-autocomplete>
            <v-btn
              style="margin-top: -100px"
              color="primary"
              class="mt-1"
              small
              dark
              v-if="!showSearchItemInput"
              @click="addTableRow()"
            >
              <v-icon left>mdi-plus</v-icon>Add Row
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row v-else-if="tab.WithTable == 2">
      <v-col
        v-for="(dataField, i) in tab.Fields"
        v-bind:key="i"
        :cols="dataField.ColumnWidth"
      >
        <v-text-field
          v-if="dataField.FieldType == 'textfield'"
          v-model="record[dataField.FieldName]"
          :label="dataField.Label"
        ></v-text-field>

        <text-area
          v-if="dataField.FieldType == 'textarea'"
          :setup="dataField"
        ></text-area>

        <v-autocomplete
          v-show="dataField.FieldType == 'select'"
          v-model="record[dataField.FieldName]"
          :label="dataField.Label"
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-textarea
          outlined
          v-model="JrnlMemo"
          label="Journal Remarks"
          rows="2"
        ></v-textarea>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          outlined
          v-model="UseShpdGd"
          label="Use Shipped Good Account"
          true-value="Y"
          false-value="N"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row v-else-if="tab.WithTable == 3">
      <attachment-editor
        @attachmentsSelected="setAttchments"
        :initialHeaderRecord="initialHeaderRecord"
      ></attachment-editor>
    </v-row>

    <v-row v-else-if="tab.WithTable == 4">
      <v-col v-for="(dataField, i) in UDFs" v-bind:key="i" cols="3">
        <v-autocomplete
          v-if="dataField.FieldType == 'select'"
          dense
          outlined
          v-model="UserFields[i]"
          :label="dataField.DispField"
          :items="dataField.items"
        ></v-autocomplete>

        <v-text-field
          v-else
          v-model="UserFields[i]"
          dense
          outlined
          :label="dataField.DispField"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- one item selection modal -->
    <v-dialog v-model="itemDialog" width="950">
      <v-toolbar dark color="primary">
        <v-toolbar-title>List of Items</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="itemDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchItem"
                    append-icon="mdi-magnify"
                    label="Select using Item Code or Item Name"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clickedItem"
                  :items-per-page="itemsPerPage"
                  v-model="selectedItems"
                  :headers="itemMasterHeaders"
                  :items="itemMasterData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                  :loading="loading"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of item modal -->

    <!-- start of item modal -->
    <v-row>
      <v-dialog
        v-model="advancedDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="accent">
            <v-toolbar-title>
              <v-btn icon dark @click.native="advancedDialog = false">
                <v-icon dark>mdi-keyboard-backspace</v-icon> </v-btn
              >Item (Advanced View)
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click.native="advancedDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row v-if="tab.tableRowsModalVisible" dense mt-2 style="margin-top: 20px">
              <v-col cols="3">
                <v-text-field
                  label="Item Code"
                  filled
                  readonly
                  outlined
                  dense
                  v-model="selectedModalItem.ItemCode"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Item Description"
                  filled
                  readonly
                  outlined
                  dense
                  v-model="selectedModalItem.Dscription"
                ></v-text-field>
              </v-col>
              <v-col
                v-for="(dataField, i) in tab.tableRowsModalVisible"
                v-bind:key="i"
                :cols="dataField.ColumnWidth"
              >
                <v-text-field
                  outlined
                  dense
                  v-if="dataField.FieldType == 'textfield'"
                  :readonly="dataField.readonly == 'Y' ? trueValue : falseValue"
                  :filled="dataField.readonly == 'Y' ? trueValue : falseValue"
                  v-model="itemRecord[dataField.value]"
                  :label="dataField.text"
                ></v-text-field>

                <v-checkbox
                  v-if="dataField.FieldType == 'checkbox'"
                  v-model="itemRecord[dataField.value]"
                  :label="dataField.text"
                  true-value="Y"
                  false-value="N"
                ></v-checkbox>

                <v-autocomplete
                  outlined
                  dense
                  v-show="dataField.FieldType == 'select'"
                  v-model="itemRecord[dataField.value]"
                  :label="dataField.text"
                  :items="getData(dataField.data)"
                  :item-text="dataField.itemText"
                  :item-value="dataField.itemValue"
                ></v-autocomplete>

                <DatePicker
                  v-if="dataField.FieldType == 'date'"
                  @date="setDate"
                  :myDate="itemRecord[dataField.value]"
                  :title="`${dataField.text}`"
                ></DatePicker>

                <v-radio-group
                  v-if="dataField.FieldType == 'radio'"
                  v-model="itemRecord[dataField.value]"
                  row
                >
                  <template v-slot:label>
                    <div>
                      <strong>{{ dataField.text }}</strong>
                    </div>
                  </template>
                  <v-radio label="Yes" value="Y"></v-radio>
                  <v-radio label="No" value="N"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn color="primary" small @click="updateModalItem">
                  <v-icon>mdi-plus</v-icon>Update Row
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- end of item modal -->

    <!-- Delete Row Dialog -->
    <v-dialog v-model="deleteDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="red" dark>
          <v-toolbar-title>DELETE ROW</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                Are you sure want you want to delete
                <span v-if="deleteItem">{{ deleteItem.Dscription }}</span
                >?
              </v-col>
              <v-col cols="12">
                <v-btn color="red" dark @click="deleteRow">Yes</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end Delete Row Dialog-->

    <!-- category quick add dialog -->
    <v-dialog v-model="changeDocTypeDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="red" dark>
          <v-toolbar-title>Change Document Type</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="resetDocType">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12"> Changes will cause data to be deleted, Continue? </v-col>
              <v-col cols="12">
                <v-btn color="red" dark @click="setDocTypeEffect">Yes</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->

    <!-- start of serial modal -->
    <v-dialog
      v-model="serialDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="accent">
          <v-toolbar-title>
            <v-btn icon dark @click="serialDialog = false">
              <v-icon dark>mdi-keyboard-backspace</v-icon> </v-btn
            >Serial Number Selection
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-1"
            outlined
            color="toolbarIcon"
            text
            dark
            @click="serialDialog = false"
          >
            <v-icon left dark>mdi-plus</v-icon>Save
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :items-per-page="itemsPerPage"
                  v-model="selectedItemWithDocs"
                  :headers="serialHeaders"
                  :items="saleItemsDataSerialManaged"
                  :single-select="singleSelect"
                  hide-default-footer
                  item-key="LineNum"
                  show-select
                ></v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchAvailableSerial"
                    append-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :items-per-page="5"
                  v-model="selected"
                  :single-select="singleSelect"
                  :headers="serialSelectedHeaders"
                  :items="osrn"
                  item-key="id"
                  class="elevation-1"
                  :search="searchAvailableSerial"
                >
                  <!-- action template -->
                  <template v-slot:item.action="{ item }">
                    <v-btn @click="clickedSerialNumber(item)" color="green" icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <!-- end -->
                </v-data-table>
              </v-col>
              <v-col cols="6">
                <v-card-title> Selected Serial Numbers </v-card-title>
                <v-data-table
                  @item-selected="removeSerialNumber"
                  :items-per-page="itemsPerPage"
                  v-model="selectedSerials"
                  :headers="serialSelectedHeaders"
                  :items="serialMasterData"
                  :single-select="singleSelect"
                  hide-default-footer
                  item-key="id"
                >
                  <!-- action template -->
                  <template v-slot:item.action="{ item }">
                    <v-btn @click="removeSerialNumber(item)" color="red" icon>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <!-- end -->
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of serial modal-->

    <!-- one item Copy From modal -->
    <v-dialog
      v-model="copyFromDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-toolbar dark color="primary">
        <v-toolbar-title>Customer Documents</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="copyFromDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchCopyFromDocs"
                    append-icon="mdi-magnify"
                    label="Filter Documents"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clickedItem"
                  :items-per-page="itemsPerPage"
                  v-model="selectedItems"
                  :headers="copyFromDocumentHeaders"
                  :items="baseDocuments"
                  :search="searchCopyFromDocs"
                  :single-select="singleSelect"
                  item-key="id"
                  class="elevation-1"
                  :loading="loading"
                >
                  <template v-slot:item.action="{ item }">
                    <v-btn @click="getCopyToDocument(item)" color="green" icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end one item Copy From modal -->

    <!-- category quick add dialog -->
    <v-dialog v-model="setDefaultWarehouseDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="primary" dark>
          <v-toolbar-title>Warehouse Selection</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="setDefaultWarehouseDialog == false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12"> Use this warehouse for all the rows, Continue? </v-col>
              <v-col cols="12">
                <v-row>
                  <v-btn color="primary" dark @click="setWarehouseDefaultForAllItems"
                    >Yes</v-btn
                  >

                  <v-spacer></v-spacer>

                  <v-btn
                    color="red"
                    dark
                    @click.native="setDefaultWarehouseDialog == false"
                    >No</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->

    <!-- start price calculator modal -->
    <template>
      <div class="text-center pa-5">
        <v-dialog v-model="calculatePriceModal" width="auto"
          ><v-sheet width="450" height="400" class="mx-auto pa-5">
            <v-form @submit.prevent @submit="copyCalculatedPrice">
              <v-toolbar-title>Price Calculator </v-toolbar-title>
              <v-template class="mb-2">
                <v-text-field
                  v-model="modalPrice"
                  type="text"
                  :rules="priceRule"
                  label="Unit Price"
                ></v-text-field>
              </v-template>
              <v-sheet class="row mt-3">
                <v-btn type="button" @click="modalPrice += 1" class="ma-2">1</v-btn>
                <v-btn type="button" @click="modalPrice += 2" class="ma-2">2</v-btn>
                <v-btn type="button" @click="modalPrice += 3" class="ma-2">3</v-btn>
                <v-btn type="button" @click="modalPrice += '/'" class="ma-2">/</v-btn>
                <v-btn type="button" @click="modalPrice += '*'" class="ma-2">x</v-btn>
                <v-btn type="button" @click="modalPrice += 4" class="ma-2">4</v-btn>
                <v-btn type="button" @click="modalPrice += 5" class="ma-2">5</v-btn>
                <v-btn type="button" @click="modalPrice += 6" class="ma-2">6</v-btn>
                <v-btn type="button" @click="modalPrice += '+'" class="ma-2">+</v-btn>
                <v-btn type="button" @click="modalPrice += '-'" class="ma-2">-</v-btn>
                <v-btn type="button" @click="modalPrice += 7" class="ma-2">7</v-btn>
                <v-btn type="button" @click="modalPrice += 8" class="ma-2">8</v-btn>
                <v-btn type="button" @click="modalPrice += 9" class="ma-2">9</v-btn>
                <v-btn type="button" @click="modalPrice += 0" class="ma-2">0</v-btn>
                <v-btn type="button" @click="modalPrice += '.'" class="ma-2">.</v-btn>
              </v-sheet>
              <v-template
                class="d-flex flex-row justify-content-between mt-6"
                width="400"
              >
                <v-btn
                  type="button"
                  class="ma-2"
                  @click="modalPrice = modalPrice.slice(0, -1)"
                >
                  <v-icon>mdi-arrow-left</v-icon></v-btn
                >
                <v-btn type="button" class="ma-2" @click="modalPriceResult"> =</v-btn>
                <v-btn type="button" class="ma-2" @click="calculatePriceModal = false"
                  >close</v-btn
                >
                <v-btn type="submit" class="ma-2">copy to field</v-btn>
              </v-template>
            </v-form>
          </v-sheet>
        </v-dialog>
      </div>
    </template>
    <!-- end modal -->

    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>
    <!-- end of snackbar -->
  </v-container>
</template>

<script>
//Changs
import { lineTotals } from "../methods/lineTotals.js";
import axios from "axios";
import AttachmentEditor from "./attachment-editor.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    AttachmentEditor,
  },
  props: {
    UDFs: {
      type: Array,
    },
    tab: {
      type: Object,
    },
    selectedTenant: {
      type: Object,
    },
    initial: {
      type: Object,
    },
    initialRows: {
      type: Array,
    },
    NewBPLId: {
      type: String,
    },
    activeObject: {
      type: String,
    },
    dealerDiscount: {
      type: Number,
    },
    docCreate: {
      type: Boolean,
    },
    docCopyTo: {
      type: Boolean,
    },
    GroupNum: {
      type: Number,
    },
  },
  data() {
    return {
      priceRule: [],
      record: {},
      UserFields: [],
      uploadFiles: [],
      deleteItem: {},
      trueValue: true,
      falseValue: false,
      ManualValue: "Manual",
      searchItem: null,
      searchAvailableSerial: null,
      searchCopyFromDocs: null,
      selectedBranch: null,
      singleSelect: true,
      advancedDialog: false,
      calculatePriceModal: false,
      modalPrice: "",
      selectedRow: {},
      activeRow: null,
      DocType: null,
      saleItemsData: [],
      selectedItems: [],
      uoms: [],
      selectedDocs: [],
      selectedItemWithDocs: [],
      taxGroups: [],
      glAccounts: [],
      costCenters: [],
      distributionRules1: [],
      distributionRules2: [],
      distributionRules3: [],
      distributionRules4: [],
      distributionRules5: [],
      warehouses: [],
      FromWhsCode: "",
      ToWhsCode: "",
      AllWarehouses: [],
      seriesData: [],
      itemRecord: {},
      baseDocuments: [],
      selectedModalItem: {},
      itemDialog: false,
      deleteDialog: false,
      changeDocTypeDialog: false,
      lineTotalIsReadonly: true,
      unitPriceIsReadonly: false,
      itemsPerPage: 100,
      itemMasterData: [],
      JrnlMemo: "",
      UseShpdGd: null,
      attachments: [],
      defaultWarehouse: "",
      cannotSetDefaultWarehouse: true,
      initialHeaderRecord: {},
      dataFromClip: null,
      itemMasterHeaders: [
        { text: "Item Code", value: "ItemCode" },
        { text: "Item Description", value: "ItemName" },
        { text: "In Stock", value: "OnHand" },
      ],
      serialHeaders: [
        { text: "Item Code", value: "ItemCode" },
        { text: "Item Description", value: "Dscription" },
        { text: "Whse Code", value: "WhsCode" },
        { text: "Quantity", value: "Quantity" },
      ],
      allSerialHeaders: [{ text: "Serial", value: "DistNumber" }],

      serialSelectedHeaders: [
        { text: "Serial", value: "DistNumber" },
        { text: "MnfSerial", value: "MnfSerial" },
        { text: "LotNumber", value: "LotNumber" },
        { text: "Colour", value: "Colour" },
        { text: "Action", value: "action" },
      ],
      OcrCode: null,
      docTypes: [
        { name: "Items", value: "I" },
        { name: "Service", value: "S" },
      ],
      promototionsTypes: [
        { name: "Free Of Charge", value: "FOC" },
        { name: "Charged", value: "Charged" },
      ],
      copyFromDocumentHeaders: [
        { text: "Doc No.", value: "DocNum" },
        { text: "SAP #", value: "ExtRefDocNum" },
        { text: "Customer Name", value: "CardName" },
        { text: "Date", value: "DocDate" },
        { text: "Total", value: "DocTotal" },
        { text: "Action", value: "action" },
      ],
      tableRows: [],
      serialMasterData: [],
      serialDialog: false,
      search: null,
      selected: [],
      osrn: [],
      selectedSerials: [],
      discount: 0.0,
      loading: false,
      copyDocFromObject: "",
      copyFromDocs: [],
      copyFromDialog: false,
      copyFromHeaderDocumentDetails: null,
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      selectedItemCode: null,
      showSearchItemInput: true,
      setDefaultWarehouseDialog: false,
      currentExcelDataRow: [],
      excelTitleRow: [],
      pasteFromExcel: false,
      options: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
      salesTypes: [
        { name: "SERVICE-WIP", value: "SERVICE-WIP" },
        { name: "NORMAL", value: "NORMAL" },
        { name: "INTERCOMPANY", value: "INTERCOMPANY" },
        { name: "STAFF", value: "STAFF" },
        { name: "STOCK/RECOVERY", value: "STOCK/RECOVERY" },
        { name: "ONLINE SALES", value: "ONLINE SALES" },
      ],
      pettyCash: [
        { name: "Petty Cash Request", value: "Petty Cash" },
        { name: "Purchase Request", value: "Purchase Request" },
        { name: "EFT/Cheque Request", value: "EFT/Cheque Request" },
        { name: "IOU Clearance", value: "IOU Clearance" },
        { name: "Expense Claim", value: "Expense Claim" },
        { name: "Casual Wages", value: "Casual Wages" },
        { name: "Cheque Payment Voucher", value: "Cheque Payment Voucher" },
        { name: "Petty Cash IOU Request", value: "Petty Cash IOU Request" },
      ],
    };
  },
  watch: {
    initial: {
      handler: "setInitial",
      immediate: true,
      deep: true,
    },
    UserFields: {
      handler: function (newValues) {
        newValues.forEach((value, i) => {
          const fieldName = this.UDFs[i].FieldName;
          this.setUserFields(fieldName, value);
        });
      },
      deep: true,
    },
    saleItemsData: {
      handler: "sendSaleData",
      immediate: true,
      deep: true,
    },
    initialRows: {
      handler: "setInitialRows",
      immediate: true,
    },
    NewBPLId: {
      handler: "setSelectBranch",
      immediate: true,
      deep: true,
    },
    JrnlMemo: {
      handler: "setJrnlMemo",
      immediate: true,
    },
    tab: {
      handler: "setTableRows",
      immediate: true,
      deep: true,
    },
    DocType: {
      handler: "selectedDocType",
      immediate: true,
    },
    attachments: {
      handler: "setFiles",
      immediate: true,
    },
    UseShpdGd: {
      handler: "setShpGd",
      immediate: true,
    },
    dealerDiscount: {
      handler: "setDealerDiscount",
      immediate: true,
    },
    selectedItemWithDocs: {
      handler: "itemsWithSerial",
      immediate: true,
    },
    selectedDocs: {
      handler: "clickedDocs",
      immediate: true,
    },
    searchItem: {
      handler: "searchItemMasterData",
      immediate: true,
    },
    activeObject: {
      handler: "setCopyFromDocs",
      immediate: true,
    },
    copyFromHeaderDocumentDetails: {
      handler: "setCopyFromHeaderDocumentDetails",
      immediate: true,
    },
    search: {
      handler: "searchItemMasterData",
      immediate: true,
    },
    selectedItemCode: {
      handler: "itemSearchItemCodeHasChanged",
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      // getGlobalWarehouses: "globalWarehouses/getGlobalWarehouses",
      getWarehouseData: "getWarehouseData",
      outputTaxGroups: "getOutputTaxGroups",
      inputTaxGroups: "getInputTaxGroups",
      getGlAccounts: "getActiveGlAccounts",
      distributionRules: "getDistributionRules",
      branchDistributionRules: "getBranchDistributionRules",
      getSavedUomGroups: "getUomGroups",
      localSettings: "getLocalSettings",
    }),
    saleItemsDataSerialManaged() {
      return this.saleItemsData.filter(function (e) {
        if (e.oitm) {
          if (e.oitm.ManSerNum == "Y") {
            return e;
          }
        }
      });
    },
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries.map((entry) => {
        const Description = entry.ItemName;
        return Object.assign({}, entry, { Description });
      });
    },
  },
  methods: {
    getUomGroups(UgpEntry) {
      // const UgpEntry = itm.oitm.UgpEntry;
      if (this.getSavedUomGroups[UgpEntry]) {
        this.uoms = this.getSavedUomGroups[UgpEntry].ugp1;
      } else {
        this.$store
          .dispatch("get", `/uomgroup/${UgpEntry}`)
          .then((res) => {
            // itm.UomCode = 1;
            this.uoms = res.ResponseData.ugp1;
            const newUomGroup = { ...this.getSavedUomGroups };
            newUomGroup[UgpEntry] = res.ResponseData;
            this.$store.commit("setUomGroups", newUomGroup);
          })
          .catch((err) => {
            console.log(err, "error");
          });
      }

      // end of uoms
    },
    lineTotals,
    setInitial(val) {
      if (val.document_lines && val.document_lines.length > 0) {
        //start seting udfs
        this.UDFs.forEach((udf, i) => {
          const fName = udf.FieldName;
          // if (val.UserFields.hasOwnProperty(fName)) {
          if (val.hasOwnProperty(fName)) {
            this.UserFields[i] = val[fName];
          }
        });

        //end setting udfs
        this.initialHeaderRecord = val;
        this.DocType = val.DocType;
        this.UseShpdGd = val.UseShpdGd;

        this.setDocTypeEffect();
      }
      const copyUseShpdGd = localStorage.getItem("UseShpdGd");
      if (this.docCreate && copyUseShpdGd) {
        this.UseShpdGd = copyUseShpdGd;
      }
    },
    setInitialRows(val) {
      if (val.length > 0) {
        if (this.DocType) {
          this.setDocTypeEffect();
          // this.saleItemsData = [...val];
          this.saleItemsData = Object.entries(val).map(([key, item]) => {
            console.log(key);
            // Update each item here
            // For example, you can modify a property of each item
            const UgpEntry = item.oitm.UgpEntry;
            // const UgpEntry = item.UgpEntry;
            // const UgpEntry = 1;
            if (this.getSavedUomGroups[UgpEntry]) {
              item.uoms = this.getSavedUomGroups[UgpEntry].ugp1;
            } else {
              this.$store
                .dispatch("get", `/uomgroup/${UgpEntry}`)
                .then((res) => {
                  item.uoms = res.ResponseData.ugp1;
                  const newUomGroup = { ...this.getSavedUomGroups };
                  newUomGroup[UgpEntry] = res.ResponseData;
                  this.$store.commit("setUomGroups", newUomGroup);
                })
                .catch((err) => {
                  console.log(err, "error");
                });
            }
            item.UomCode = item.oitm.SUoMEntry;
            item.UomEntry = item.oitm.SUoMEntry;
            // item.UomCode = item.SUoMEntry;
            // item.UomEntry = item.SUoMEntry;
            // item.property = newValue;

            return item;
          });
        }
      }
    },
    sendSaleData(data) {
      this.$emit("saleItemsData", data);
    },
    setJrnlMemo(data) {
      this.$emit("JrnlMemo", data);
    },
    setShpGd(data) {
      this.$emit("UseShpdGd", data);
    },
    selectedDocType(data) {
      this.setDocTypeEffect();
      this.$emit("docType", data);
    },
    setDealerDiscount(data) {
      this.discount = 0;
      if (data) {
        this.discount = data;

        if (this.saleItemsData.length > 0) {
          this.saleItemsData.forEach((doc) => {
            doc.DiscPrcnt = 0;
            if (doc.oitm.QryGroup61 == "N") {
              doc.DiscPrcnt = data;
            }
            this.updateRows(doc);
          });
        }
      }
    },

    setSelectBranch(val) {
      const self = this;
      if (val) {
        if (self.branchDistributionRules[val]) {
          const rules = self.branchDistributionRules[val];
          self.OcrCode = rules[0].OcrCode;
          self.distributionRules1 = rules;
        } else {
          this.$store
            .dispatch("get", `/distribution-rules/1?branchID=${val}`)
            .then((res) => {
              const newBranchRules = { ...self.branchDistributionRules };
              newBranchRules[val] = res.ResponseData;
              self.$store.commit("setBranchDistributionRules", newBranchRules);
              self.OcrCode = res.ResponseData[0].OcrCode;
              self.distributionRules1 = res.ResponseData;
            })
            .catch((err) => {
              console.log(err);
            });
        }

        if (self.getWarehouseData) {
          this.warehouses = this.getWarehouseData;
        } else {
          this.$store
            .dispatch("get", `/warehouse?branchID=${val}`)
            .then((res) => {
              // var globalWarehousesData = { ...self.getGlobalWarehouses };
              // globalWarehousesData[val] = res;
              // this.$store.commit("globalWarehouses/loadData", globalWarehousesData);
              this.$store.commit("setWarehouseData", res);
              this.warehouses = res;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },

    setDate() {},
    openItemModal(data) {
      if (this.DocType == null) {
        this.$refs.snackbar.show("Please select Doc Type first!", "red");
        return;
      }

      if (this.docCopyTo && data.ItemCode) {
        this.$refs.snackbar.show(
          "To change an item, Please delete the row and click add row",
          "red"
        );
        return;
      }

      this.activeRow = data.LineNum;
      this.selectedItems = [];
      this.searchItem = null;
      this.itemMasterData = [];
      this.itemDialog = true;
    },
    getData(data) {
      return this.$data[data];
    },
    visibleRows(data) {
      if (data) {
        const document_lines = data.filter((record) => {
          return record.Visible === "Y";
        });
        document_lines.forEach((doc) => {
          doc.class = "salesDataColumn";
          this.updateRows(doc);
        });
        return document_lines;
      }
    },
    clickedDocs(val) {
      if (val.length <= 0) {
        return;
      }

      this.deleteItem = val[0];
      this.deleteDialog = true;
    },
    updateModalItem() {
      const updatedItem = { ...this.selectedModalItem, ...this.itemRecord };
      const id = this.selectedModalItem.id;

      const currentItem = this.saleItemsData.find((record) => {
        return record.id === id;
      });

      const pos = this.saleItemsData.indexOf(currentItem);
      if (pos === -1) {
        return;
      }

      this.saleItemsData[pos] = updatedItem;
      this.itemRecord = {};
      this.advancedDialog = false;
    },

    updateRows(dataItem) {
      if (dataItem.ItemCode != null) {
        // get tax Rate

        if (dataItem.DocEntry != null) {
          this.getItemDetails(dataItem);
        }
        const taxId = dataItem.TaxCode;
        const selectedTax = this.taxGroups.find((record) => {
          return record.code === taxId;
        });

        let taxRate = 0;
        if (selectedTax) {
          taxRate = selectedTax.rate;
        }

        // end

        // find item
        const currentItem = this.saleItemsData.find((record) => {
          return record.LineNum === dataItem.LineNum;
        });

        // find Warehouse
        if (dataItem.WhsCode) {
          if (this.warehouses.length > 0) {
            const currentWarehouse = this.warehouses.find((record) => {
              return record.WhsCode === dataItem.WhsCode;
            });
            currentItem.WhsName = currentWarehouse.WhsName;
          }

          //Set In Stock
          if (currentItem.oitw != undefined) {
            if (currentItem.oitw.length > 0) {
              const currentWarehouseInventory = currentItem.oitw.find((record) => {
                return record.WhsCode === dataItem.WhsCode;
              });

              currentItem.U_StockWhse = currentWarehouseInventory
                ? currentWarehouseInventory.OnHand
                : 0;
              currentItem.U_ItemCost = currentWarehouseInventory
                ? currentWarehouseInventory.AvgPrice
                : 0;
            }
          }
        }

        // end of find
        const pos = this.saleItemsData.indexOf(currentItem);
        if (pos === -1) {
          return;
        }
        // end of find

        // get computed values
        const details = {
          Quantity: dataItem.Quantity,
          PriceBefDi: dataItem.PriceBefDi,
          Discount: dataItem.DiscPrcnt,
          Tax: taxRate,
        };

        const computedValues = this.lineTotals(details);
        currentItem.Price = computedValues.Price;
        currentItem.LineTotal = computedValues.LineTotal.toFixed(2);
        currentItem.PriceAfVAT = computedValues.PriceAfVAT.toFixed(2);

        currentItem.VatSum = computedValues.VatSum;
        currentItem.GTotal = computedValues.GTotal;
        // end of computation
        // update row
        this.saleItemsData[pos] = currentItem;
      }

      /**
       * Data Changes on Services
       *
       */

      if (this.DocType == "S") {
        // get tax Rate
        const taxId = dataItem.TaxCode;
        const selectedTax = this.taxGroups.find((record) => {
          return record.code === taxId;
        });

        let taxRate = 0;
        if (selectedTax) {
          taxRate = selectedTax.rate;
        }
        // end

        // find item
        const currentItem = this.saleItemsData.find((record) => {
          return record.LineNum === dataItem.LineNum;
        });

        // end of find
        const pos = this.saleItemsData.indexOf(currentItem);
        if (pos === -1) {
          return;
        }
        // end of find

        // get computed values
        const details = {
          Quantity: this.DocType == "I" ? dataItem.Quantity : 1,
          PriceBefDi: dataItem.PriceBefDi,
          Discount: dataItem.DiscPrcnt,
          DocType: this.DocType,
          Tax: taxRate,
        };

        const computedValues = this.lineTotals(details);
        currentItem.Price = computedValues.Price;
        currentItem.LineTotal = computedValues.LineTotal;
        currentItem.PriceAfVAT = computedValues.PriceAfVAT;
        currentItem.VatSum = computedValues.VatSum;
        currentItem.GTotal = computedValues.GTotal;
        // end of computation

        // update row
        this.saleItemsData[pos] = currentItem;
      }
    },

    inputChanged(data) {
      this.updateRows(data);
    },
    modalPriceResult() {
      const result = eval(this.modalPrice);
      this.modalPrice = result.toString();
    },
    copyCalculatedPrice() {
      const price = eval(this.modalPrice);
      this.calculatePriceModal = false;
      var data = this.selectedRow;
      data.PriceBefDi = Math.ceil(price / 0.01) * 0.01;
      this.updateRows(data);
      this.modalPrice = "";
    },
    warehouseChanged(data) {
      this.updateRows(data);

      if (!this.defaultWarehouse && !this.cannotSetDefaultWarehouse) {
        if (this.saleItemsData.length > 2) {
          this.defaultWarehouse = data.WhsCode;
          this.setDefaultWarehouseDialog = true;
          this.cannotSetDefaultWarehouse = true;
        }
      }
    },

    changedCostCenter(item) {
      const id = item.LineNum;
      // find the selected row and update
      const currentItem = this.saleItemsData.find((record) => {
        return record.LineNum === id;
      });

      const pos = this.saleItemsData.indexOf(currentItem);
      if (pos === -1) {
        return;
      }

      const self = this;
      currentItem.CogsOcrCo2 = item.OcrCode2;
      currentItem.CogsOcrCo3 = item.OcrCode3;
      self.saleItemsData[pos] = currentItem;
    },

    changedUom(item) {
      const data = {
        ItemCode: item.ItemCode,
        CardCode: this.selectedTenant.CardCode,
        SUoMEntry: item.UomCode,
        priceList: this.GroupNum,
      };

      const id = item.LineNum;
      // find the selected row and update
      const currentItem = this.saleItemsData.find((record) => {
        return record.LineNum === id;
      });

      const pos = this.saleItemsData.indexOf(currentItem);
      if (pos === -1) {
        return;
      }

      // get tax rate
      const taxId = item.TaxCode;
      const selectedTax = this.taxGroups.find((record) => {
        return record.code === taxId;
      });

      let taxRate = 0;
      if (selectedTax) {
        taxRate = selectedTax.rate;
      }
      const currentSUomEntryObject = this.currentItem.uoms.find((record) => {
        return record.uomentry.id === item.SUoMEntry;
      });
      // end of tax get
      item.UomObject = currentSUomEntryObject.uomentry;
      // make prices query request
      const self = this;
      const url = "/getDefaultPrice";
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            const priceData = res.ResponseData;
            this.updatePriceOnChangeUom(currentItem, item, priceData, taxRate);
            // end of computation
          } else {
            self.$refs.snackbar.show(
              "Ensure the selected customer has a price List",
              "red"
            );
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
      // end of request

      // set defaults
      self.saleItemsData[pos] = currentItem;
    },
    clickedItem($event) {
      //Chnages
      // if (this.selectedTenant.id !== undefined || this.activeObject !== 205 || this.activeObject !== 66)
      if (
        this.selectedTenant.id !== undefined ||
        this.activeObject !== 205 ||
        this.activeObject !== 66
      ) {
        this.selectedItem = $event.item;

        const sItem = $event.item;

        const data = {
          ObjType: this.activeObject, //Document Type
          ItemCode: $event.item.id,
          CardCode: this.selectedTenant.CardCode,
          priceList: this.GroupNum,
          SUoMEntry: null,
        };

        const id = this.activeRow;
        // find the selected row and update
        const currentItem = this.saleItemsData.find((record) => {
          return record.LineNum === id;
        });

        const pos = this.saleItemsData.indexOf(currentItem);
        if (pos === -1) {
          return;
        }

        // get tax rate
        let taxId = sItem.VatGourpSa;
        let TaxCode = sItem.VatGourpSa;

        if (this.activeObject == 205) {
          taxId = sItem.VatGroupPu;
          TaxCode = sItem.VatGroupPu;
        }

        const selectedTax = this.taxGroups.find((record) => {
          return record.code === taxId;
        });

        let taxRate = 0;
        if (selectedTax) {
          taxRate = selectedTax.rate;
        }
        // end of tax get

        currentItem.Dscription = sItem.ItemName;
        currentItem.ItemCode = sItem.ItemCode;
        currentItem.UomCode = sItem.SUoMEntry;
        currentItem.UomEntry = sItem.SUoMEntry;
        currentItem.id = sItem.id;
        currentItem.Quantity = 1;
        currentItem.UseBaseUn = "N";
        currentItem.PackQty = 1;
        currentItem.PickStatus = "N";
        currentItem.U_StockWhse = 0;
        currentItem.U_ItemCost = 0;
        currentItem.WhsCode = null;
        currentItem.IsManual = null;
        currentItem.TaxCode = TaxCode;
        currentItem.ManSerNum = sItem.ManSerNum; //To User to check for serial
        currentItem.oitm = sItem;
        // end of row find

        // make prices query request
        const self = this;
        const url = "/getDefaultPrice";
        this.$store
          .dispatch("post", { url, data })
          .then((res) => {
            if (res.ResultCode == 1200) {
              const priceData = res.ResponseData;
              currentItem.PriceBefDi = priceData.FINALSALESPRICE;
              currentItem.NumPerMsr = priceData.SalesUnitINVUnitConversion;

              currentItem.U_AllowDisc = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.U_AllowDisc
                : null;
              //Current Inventory Details
              currentItem.oitw = priceData.oitw;

              //Dimensions Defaults
              currentItem.OcrCode = self.OcrCode;
              currentItem.CogsOcrCod = self.OcrCode;

              currentItem.OcrCode2 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode2
                : null;
              currentItem.CogsOcrCo2 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode2
                : null;

              currentItem.OcrCode3 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode3
                : null;
              currentItem.CogsOcrCo3 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode3
                : null;

              currentItem.OcrCode4 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode4
                : null;
              currentItem.CogsOcrCo4 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode4
                : null;

              currentItem.OcrCode5 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode5
                : null;
              currentItem.CogsOcrCo5 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode5
                : null;

              // get computed values
              let rowDiscount = 0;
              if (this.Discount > 0) {
                rowDiscount = 0;
                if (sItem.QryGroup61 == "N") {
                  rowDiscount = this.discount > 0 ? this.discount : 0;
                }
              }

              const details = {
                Quantity: 1,
                PriceBefDi: priceData.FINALSALESPRICE,
                Discount: rowDiscount,
                Tax: taxRate,
              };

              const computedValues = self.lineTotals(details);
              currentItem.Price = computedValues.Price;
              currentItem.LineTotal = computedValues.LineTotal;
              currentItem.PriceAfVAT = computedValues.PriceAfVAT.toFixed(2);
              currentItem.VatSum = computedValues.VatSum.toFixed(2);
              currentItem.GTotal = computedValues.GTotal;

              // end of computation
            } else {
              self.$refs.snackbar.show(res.ResultDesc, "red");
            }
          })
          .catch((err) => {
            console.log(err, "err");
          });
        // end of request

        /**
         * Set Delaer Discount
         */
        currentItem.DiscPrcnt = 0;

        if (this.discount > 0) {
          if (sItem.QryGroup61 == "N") {
            currentItem.DiscPrcnt = this.discount > 0 ? this.discount : 0;
          }
        }

        // set defaults
        self.saleItemsData[pos] = currentItem;
        // end
        // fetch items uoms
        const UgpEntry = this.selectedItem.UgpEntry;
        this.$store
          .dispatch("get", `/uomgroup/${UgpEntry}`)
          .then((res) => {
            currentItem.uoms = res.ResponseData.ugp1;
            // console.log("uoms");
          })
          .catch((err) => {
            console.log(err, "error");
          });
        // end of uoms
        this.inputChanged(currentItem);
        // end of default values
        this.itemDialog = false;
      } else {
        this.selectedItems = [];
        this.itemRecord = {};
        this.itemDialog = false;
        this.dialog = false;
        this.$refs.snackbar.show("Please select Customer first!", "red");
      }
    },

    updatePriceOnChangeUom(currentItem, item, priceData, taxRate) {
      currentItem.PriceBefDi = priceData.FINALSALESPRICE;
      currentItem.StockPrice = priceData.FINALSALESPRICE;
      currentItem.NumPerMsr = priceData.SalesUnitINVUnitConversion;
      // get computed values
      const details = {
        Quantity: item.Qty || 1,
        PriceBefDi: priceData.FINALSALESPRICE,
        Discount: item.DiscPrcnt || 0,
        Tax: taxRate,
      };

      const computedValues = self.lineTotals(details);
      currentItem.Price = computedValues.Price;
      currentItem.LineTotal = computedValues.LineTotal;
      currentItem.PriceAfVAT = computedValues.PriceAfVAT;
      currentItem.VatSum = computedValues.VatSum;
      currentItem.GTotal = computedValues.GTotal;
    },

    async updateDefaultPricesOnClickItem(currentItem, sItem, priceData, taxRate) {
      const self = this;
      currentItem.PriceBefDi = priceData.FINALSALESPRICE;
      currentItem.NumPerMsr = priceData.SalesUnitINVUnitConversion;

      currentItem.U_AllowDisc = priceData ? priceData.U_AllowDisc : "Y";
      //Current Inventory Details
      currentItem.oitw = priceData.oitw;
      //Dimensions Defaults

      // get computed values
      let rowDiscount = 0;
      if (this.Discount > 0) {
        rowDiscount = 0;
        if (sItem.QryGroup61 == "N") {
          rowDiscount = this.discount > 0 ? this.discount : 0;
        }
      }

      const details = {
        Quantity: 1,
        PriceBefDi: priceData.FINALSALESPRICE,
        Discount: rowDiscount,
        Tax: taxRate,
      };

      const computedValues = self.lineTotals(details);

      currentItem.Price = computedValues.Price;
      currentItem.LineTotal = computedValues.LineTotal;
      currentItem.PriceAfVAT = computedValues.PriceAfVAT.toFixed(2);
      currentItem.VatSum = computedValues.VatSum.toFixed(2);
      currentItem.GTotal = computedValues.GTotal;
      currentItem.OcrCode = self.OcrCode;
      currentItem.CogsOcrCod = self.OcrCode;

      const url = "/fetch-item-with-code";
      const data = {
        ObjType: this.activeObject, //Document Type
        ItemCode: sItem.ItemCode,
        CardCode: this.selectedTenant.CardCode,
        priceList: this.GroupNum,
        type: "defaults",
        variable: sItem.QryGroup61,
      };

      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            const defaultssdata = res.ResponseData.ItemDimensionDfts;

            currentItem.OcrCode2 = defaultssdata ? defaultssdata.OcrCode2 : null;
            currentItem.CogsOcrCo2 = defaultssdata ? defaultssdata.OcrCode2 : null;

            currentItem.OcrCode3 = defaultssdata ? defaultssdata.OcrCode3 : null;
            currentItem.CogsOcrCo3 = defaultssdata ? defaultssdata.OcrCode3 : null;

            currentItem.OcrCode4 = defaultssdata ? defaultssdata.OcrCode4 : null;
            currentItem.CogsOcrCo4 = defaultssdata ? defaultssdata.OcrCode4 : null;

            currentItem.OcrCode5 = defaultssdata ? defaultssdata.OcrCode5 : null;
            currentItem.CogsOcrCo5 = defaultssdata ? defaultssdata.OcrCode5 : null;
          } else {
            this.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },

    searchItemMasterData(val) {
      if (!val) {
        this.entries = [];
        return;
      }

      this.fetchEntriesDebounced();
    },
    fetchEntriesDebounced() {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        if (this.search) {
          if (!this.localSettings.enable) {
            this.getItemMasterData2();
          }
          if (this.localSettings.enable) {
            this.getItemMasterDataLocal();
          }
        }
        if (this.searchItem) {
          this.getItemMaster();
        }
      }, 50);
    },
    getItemMaster() {
      this.loading = true;
      const self = this;
      this.$store
        .dispatch("get", `/item_masterdata?search=${this.searchItem}`)
        .then((res) => {
          self.itemMasterData = res.ResponseData.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      this.loading = false;
    },

    save() {
      this.$refs.snackbar.show("Data saved", "green");
    },
    cancel() {
      this.$refs.snackbar.show("Cancelled", "green");
    },
    open() {},
    close() {},
    addTableRow() {
      if (this.DocType == null) {
        this.$refs.snackbar.show("Please select Doc Type first!", "red");
        return;
      }

      const itemsCount = this.saleItemsData.length;
      const newCount = itemsCount + 1;
      this.activeRow = itemsCount;

      const details = {
        rowNum: new Date().getTime(),
        DocEntry: null,
        RowId: newCount,
        id: newCount,
        ItemCode: this.selectedItemCode ?? null,
        LineNum: itemsCount,
        Dscription: null,
        CodeBars: null,
        SerialNum: null,
        Quantity: null,
        DelivrdQty: null,
        UseBaseUn: null,
        PackQty: null,
        PriceBefDi: null,
        DiscPrcnt: null,
        Rate: null,
        Price: null,
        StockPrice: null,
        TaxCode: null,
        PriceAfVAT: null,
        LineTotal: null,
        WhsCode: null,
        ShipDate: null,
        SlpCode: null,
        Commission: null,
        TreeType: 1,
        LineStatus: null,
        TargetType: null,
        TargetEntry: null,
        BaseType: null,
        BaseRef: null,
        BaseEntry: null,
        BaseLine: null,
        SpecPrice: 1,
        VatSum: null,
        GrssProfit: null,
        PoTrgNum: null,
        BackOrdr: null,
        FreeTxt: null,
        PickStatus: null,
        UomCode: null,
        UomEntry: null,
        UomObject: {},
        unitMsr: null,
        NumPerMsr: null,
        Text: null,
        GTotal: null,
        UgpCode: null,
        AgrNo: null,
        LinePoPrss: "N",
        NoInvtryMv: "N",
        OnHand: null,
        IsCommited: null,
        OnOrder: null,
        OpenQty: null,
        InvQty: null,
        OpenInvQty: null,
        OwnerCode: null,
        TrnsCode: null,
        OrigItem: null,
        GPTtlBasPr: null,
        GrossBuyPr: null,
        OcrCode5: null,
        OcrCode4: null,
        OcrCode3: null,
        OcrCode2: null,
        OcrCode: null,
        AcctCode: null,
        oitw: [],
        uoms: [],
        ManSerNum: null,
        SerialNumbers: [],
        oitm: [],
        U_Promotion: "Charged",
      };

      if (this.discount != 0) {
        details.DiscPrcnt = 0;
      }

      if (this.DocType === "S") {
        // get general settings
        this.$store
          .dispatch("get", `/general_settings`)
          .then((res) => {
            if (this.activeObject != 205) {
              details.TaxCode = res.ResponseData.DfSVatServ;
            }
            if (this.activeObject == 205) {
              details.TaxCode = res.ResponseData.DfPVatServ;
            }
          })
          .catch((err) => {
            console.log(err, "error");
          });
        // end of general settings query
      }

      this.saleItemsData = [...this.saleItemsData, details];

      var elem = document.getElementById("tableDataDiv");
      elem.scrollTop = elem.scrollHeight;
    },

    getTaxGroups() {
      const self = this;
      if (this.activeObject == 205) {
        if (self.inputTaxGroups.length > 0) {
          self.taxGroups = self.inputTaxGroups;
        } else {
          this.$store
            .dispatch("get", `/taxgroups/input`)
            .then((res) => {
              self.taxGroups = res;
              self.$store.commit("setInputTaxGroups", res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        if (self.outputTaxGroups.length > 0) {
          self.taxGroups = self.outputTaxGroups;
        } else {
          this.$store
            .dispatch("get", `/taxgroups/output`)
            .then((res) => {
              self.taxGroups = res;
              self.$store.commit("setOutputTaxGroups", res);
            })
            .catch((err) => {
              this.$refs.snackbar.show(err, "red");
            });
        }
      }
    },

    getCostCenters() {
      const self = this;
      this.$store
        .dispatch("get", `/cost-centers`)
        .then((res) => {
          self.costCenters = res.ResponseData;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDistributionRules(id) {
      const self = this;
      if (self.distributionRules[id]) {
        if (id == 1) {
          self.distributionRules1 = self.distributionRules[id];
        }
        if (id == 2) {
          self.distributionRules2 = self.distributionRules[id];
        }
        if (id == 3) {
          self.distributionRules3 = self.distributionRules[id];
        }
        if (id == 4) {
          self.distributionRules4 = self.distributionRules[id];
        }
        if (id == 5) {
          self.distributionRules5 = self.distributionRules[id];
        }
      } else {
        this.$store
          .dispatch("get", `/distribution-rules/${id}`)
          .then((res) => {
            if (id == 1) {
              self.distributionRules1 = res.ResponseData;
            }
            if (id == 2) {
              self.distributionRules2 = res.ResponseData;
            }
            if (id == 3) {
              self.distributionRules3 = res.ResponseData;
            }
            if (id == 4) {
              self.distributionRules4 = res.ResponseData;
            }
            if (id == 5) {
              self.distributionRules5 = res.ResponseData;
            }
            var distRules = { ...self.distributionRules };
            // if (!distRules[id]) {
            //   distRules[id] = {};
            // }
            // const newData = { id: { "Default": res.ResponseData } };
            // distRules[id]["Default"] = newData[id]["Default"];
            distRules[id] = res.ResponseData;
            self.$store.commit("setDistributionRules", distRules);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    generalSettings() {
      const self = this;
      this.$store
        .dispatch("get", `/general_settings`)
        .then((res) => {
          self.OcrCode = res.ResponseData;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getWarehouses() {
      const userData = this.$store.state.user;

      if (userData.oadm.filterWarehouseByBranch === "N") {
        const self = this;
        this.$store
          .dispatch("get", `/warehouse`)
          .then((res) => {
            self.warehouses = res.ResponseData;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    setAttchments(data) {
      this.attachments = data;
    },
    setFiles(data) {
      this.$emit("attachments", data);
    },
    setCopyFromHeaderDocumentDetails(data) {
      if (data) {
        this.JrnlMemo = data.JrnlMemo;
        localStorage.setItem("UseShpdGd", data.UseShpdGd);
        this.$emit("copyFromHeaderDocumentDetails", data);
      }
    },
    deleteRow() {
      const self = this;
      const item = this.saleItemsData.find((record) => {
        return record.LineNum === self.deleteItem.LineNum;
      });
      const pos = this.saleItemsData.indexOf(item);
      if (pos === -1) {
        return;
      }
      this.saleItemsData.splice(pos, 1);

      this.saleItemsData.forEach((doc) => {
        doc.LineNum = Math.random() * 100 + 1;
        this.updateRows(doc);
      });
      this.selectedDocs = [];
      this.deleteDialog = false;
    },
    getActiveGLAccounts() {
      const self = this;
      //this.loading = true;
      if (self.getGlAccounts) {
        self.glAccounts = self.getGlAccounts;
      } else {
        this.$store
          .dispatch("get", `/activeGLaccounts?type=Revenue`)
          .then((res) => {
            self.glAccounts = res;
            var glAccounts = { ...this.getGlAccounts };
            glAccounts["Revenue"] = res;
            this.$store.commit("setActiveGlAcounts", glAccounts);
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
      }
    },
    setTableRows(data) {
      const self = this;
      self.tableRows = data.tableRows;
    },
    setDocType() {
      localStorage.removeItem("docType");
      localStorage.setItem("docType", this.DocType);
      if (this.saleItemsData.length > 0) {
        this.changeDocTypeDialog = true;
      } else {
        this.setDocTypeEffect();
      }
    },
    resetDocType() {
      if (this.DocType == "I") {
        this.saleItemsData = [];
        this.DocType = "S";
      }
      if (this.DocType == "S") {
        this.saleItemsData = [];
        this.DocType = "I";
      }

      this.changeDocTypeDialog = false;
    },

    setDocTypeEffect() {
      const self = this;
      if (self.tableRows) {
        this.changeDocTypeDialog = false;
        this.lineTotalIsReadonly = false;

        const fieldObjAcctCode = this.tableRows.find((record) => {
          return record.itemValue == "AcctCode";
        });

        const pos = this.tableRows.indexOf(fieldObjAcctCode);
        if (pos === -1) {
          return;
        }

        fieldObjAcctCode.Visible = "Y";

        this.tableRows[pos] = fieldObjAcctCode;

        //Start of ItemCode

        const fieldObjItemCode = self.tableRows.find((record) => {
          return record.value === "ItemCode";
        });

        const posItemCode = this.tableRows.indexOf(fieldObjItemCode);
        if (posItemCode === -1) {
          return;
        }
        if (this.DocType == "I") {
          this.showSearchItemInput = true;
          fieldObjItemCode.Visible = "Y";
        }
        if (this.DocType == "S") {
          this.showSearchItemInput = false;
          fieldObjItemCode.Visible = "N";
        }

        this.tableRows[posItemCode] = fieldObjItemCode;
        //End Of ItemCode

        //Start of U_StockWhse
        const fieldObjU_StockWhse = self.tableRows.find((record) => {
          return record.value === "U_StockWhse";
        });
        const posU_StockWhse = this.tableRows.indexOf(fieldObjU_StockWhse);
        if (posU_StockWhse === -1) {
          return;
        }
        if (this.DocType == "I") {
          fieldObjU_StockWhse.Visible = "Y";
        }
        if (this.DocType == "S") {
          fieldObjU_StockWhse.Visible = "N";
        }
        this.tableRows[posU_StockWhse] = fieldObjU_StockWhse;
        //End of U_StockWhse

        //Start of U_AllowDisc
        const fieldObjU_AllowDisc = self.tableRows.find((record) => {
          return record.value === "U_AllowDisc";
        });
        const posU_AllowDisc = this.tableRows.indexOf(fieldObjU_AllowDisc);
        if (posU_AllowDisc === -1) {
          return;
        }
        if (this.DocType == "I") {
          fieldObjU_AllowDisc.Visible = "Y";
        }
        if (this.DocType == "S") {
          fieldObjU_AllowDisc.Visible = "N";
        }
        this.tableRows[posU_AllowDisc] = fieldObjU_AllowDisc;
        //End of U_AllowDisc

        //Start of U_ItemCost
        const fieldObjU_ItemCost = self.tableRows.find((record) => {
          return record.value === "U_ItemCost";
        });
        const posU_ItemCost = this.tableRows.indexOf(fieldObjU_ItemCost);
        if (posU_ItemCost === -1) {
          return;
        }
        if (this.DocType == "I") {
          fieldObjU_ItemCost.Visible = "Y";
        }
        if (this.DocType == "S") {
          fieldObjU_ItemCost.Visible = "N";
        }
        this.tableRows[posU_ItemCost] = fieldObjU_ItemCost;
        //End of U_ItemCost

        //Start of Quantity
        const fieldObjQuantity = self.tableRows.find((record) => {
          return record.value === "Quantity";
        });
        const posQuantity = this.tableRows.indexOf(fieldObjQuantity);
        if (posQuantity === -1) {
          return;
        }
        if (this.DocType == "I") {
          fieldObjQuantity.Visible = "Y";
        }
        if (this.DocType == "S") {
          fieldObjQuantity.Visible = "N";
        }
        this.tableRows[posQuantity] = fieldObjQuantity;
        //End Of ItemCode

        //DiscPrcnt row
        const fieldObjDiscPrcnt = self.tableRows.find((record) => {
          return record.value === "DiscPrcnt";
        });
        const posDiscPrcnt = this.tableRows.indexOf(fieldObjDiscPrcnt);
        if (posDiscPrcnt === -1) {
          return;
        }
        if (this.DocType == "I") {
          fieldObjDiscPrcnt.Visible = "Y";
        }
        if (this.DocType == "S") {
          fieldObjDiscPrcnt.Visible = "N";
        }
        this.tableRows[posDiscPrcnt] = fieldObjDiscPrcnt;
        //End Of Discount

        // Start of PriceAfVAT
        const fieldObjPriceAfVAT = self.tableRows.find((record) => {
          return record.value === "PriceAfVAT";
        });
        const posPriceAfVAT = this.tableRows.indexOf(fieldObjPriceAfVAT);
        if (posPriceAfVAT === -1) {
          return;
        }
        if (this.DocType == "I") {
          fieldObjPriceAfVAT.Visible = "Y";
        }
        if (this.DocType == "S") {
          fieldObjPriceAfVAT.Visible = "N";
        }
        this.tableRows[posPriceAfVAT] = fieldObjPriceAfVAT;
        //End Of PriceAfVAT

        // Start of WhsCode
        const fieldObjWhsCode = self.tableRows.find((record) => {
          return record.value === "WhsCode";
        });
        const posWhsCode = this.tableRows.indexOf(fieldObjWhsCode);
        if (posWhsCode === -1) {
          return;
        }
        if (this.DocType == "I") {
          fieldObjWhsCode.Visible = "Y";
        }
        if (this.DocType == "S") {
          fieldObjWhsCode.Visible = "N";
        }
        this.tableRows[posWhsCode] = fieldObjWhsCode;
        //End Of PriceAfVAT

        //Start WhsName
        const fieldObjWhsName = self.tableRows.find((record) => {
          return record.value === "WhsName";
        });
        const posWhsName = this.tableRows.indexOf(fieldObjWhsName);
        if (posWhsName === -1) {
          return;
        }
        if (this.DocType == "I") {
          fieldObjWhsName.Visible = "Y";
        }
        if (this.DocType == "S") {
          fieldObjWhsName.Visible = "N";
        }
        this.tableRows[posWhsName] = fieldObjWhsName;
        //End Of WhsName

        //Start UomCode
        const fieldObjUomCode = self.tableRows.find((record) => {
          return record.value === "UomCode";
        });
        const posUomCode = this.tableRows.indexOf(fieldObjUomCode);
        if (posUomCode === -1) {
          return;
        }

        if (this.DocType == "I") {
          this.unitPriceIsReadonly = false;
          fieldObjUomCode.Visible = "Y";
        }
        if (this.DocType == "S") {
          this.unitPriceIsReadonly = false;
          fieldObjUomCode.Visible = "N";
        }
        this.tableRows[posUomCode] = fieldObjUomCode;
        //End Of UomCode
      }
    },
    getItemDetails(selectedItem) {
      // find item
      const currentItem = this.saleItemsData.find((record) => {
        return record.id === selectedItem.id;
      });

      if (selectedItem.oitm.oitw && selectedItem.oitm.oitw.length > 0) {
        currentItem.oitw = selectedItem.oitm.oitw;
        // end of find
        const pos = this.saleItemsData.indexOf(currentItem);
        if (pos === -1) {
          return;
        }

        this.saleItemsData[pos] = currentItem;
      }
    },
    openItemSerialModal() {
      if (
        this.activeObject == 15 ||
        this.activeObject == 17 ||
        this.activeObject == 16 ||
        this.activeObject == 14 ||
        (this.activeObject == 13 && this.saleItemsData.length > 0)
      ) {
        this.serialMasterData = [];
        this.selectedItemWithDocs = [];
        this.osrn = [];
        this.serialDialog = true;
      }
    },
    itemsWithSerial(val) {
      this.serialMasterData = [];
      if (val.length <= 0) {
        this.serialMasterData = [];
        return;
      }

      const self = this;
      var itemSelected = val[0];

      if (self.selectedDocs.length > 0) {
        self.osrn = [];
        self.serialMasterData = [];
        return;
      }

      const itemID = itemSelected.oitm.id;

      if (!itemSelected.WhsCode) {
        this.$refs.snackbar.show("Select Warehouse First", "red");
        return;
      }

      if (!this.initial.BaseType) {
        if (this.docCreate && itemSelected.U_StockWhse <= 0) {
          return;
        }
      }

      if (itemSelected.SerialNumbers != null && itemSelected.SerialNumbers.length > 0) {
        itemSelected.SerialNumbers.forEach((doc) => {
          var BaseType = doc.BaseType;
          var BaseEntry = doc.BaseEntry;
          var LineNum = itemSelected.LineNum;

          if (this.docCopyTo && this.initial.BaseType != 17) {
            if (this.initial.BaseType && this.initial.BaseEntry) {
              BaseType = this.initial.BaseType;
              BaseEntry = this.initial.BaseEntry;
            }
          }

          if (
            doc.BaseType == BaseType &&
            BaseType !== undefined &&
            doc.BaseEntry == BaseEntry &&
            this.initial.BaseType != 17
          ) {
            const details = {
              id: doc.osrn ? doc.osrn.id : null,
              DistNumber: doc.osrn.DistNumber,
              SysNumber: doc.osrn.SysNumber,
              SysSerial: doc.osrn.SysNumber,
              ItemCode: itemSelected.ItemCode,
              LineNum: LineNum,
              osrn: doc.osrn,
            };
            this.serialMasterData.push(details);

            return;
          }
          const details1 = {
            id: doc.osrn ? doc.osrn.id : null,
            DistNumber: doc.osrn ? doc.osrn.DistNumber : doc.DistNumber,
            SysNumber: doc.osrn ? doc.osrn.SysNumber : doc.SysNumber,
            SysSerial: doc.osrn ? doc.osrn.SysNumber : doc.SysNumber,
            ItemCode: itemSelected.ItemCode,
            LineNum: LineNum,
            osrn: doc,
          };
          this.serialMasterData.push(details1);
          return;
        });

        self.osrn = [];

        if (this.$route.query.isDoc == 0) {
          this.$store
            .dispatch(
              "get",
              `/item_masterdata/${itemID}?WhseCode=${itemSelected.WhsCode}&ObjType=${this.activeObject}`
            )
            .then((res) => {
              if (res.ResultCode == 1200) {
                self.osrn = res.ResponseData.osrn;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      if (this.initial.BaseType == 15 && this.activeObject == 16) {
        return;
      }

      if (this.activeObject == 15 && !this.docCreate) {
        return;
      }

      if (this.initial.BaseType == 15 && this.activeObject == 13) {
        return;
      }

      if (this.initial.BaseType == 13 && this.activeObject == 16) {
        return;
      }

      self.osrn = [];

      this.$store
        .dispatch(
          "get",
          `/item_masterdata/${itemID}?WhseCode=${itemSelected.WhsCode}&ObjType=${this.activeObject}`
        )
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.osrn = res.ResponseData.osrn;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clickedSerialNumber(item) {
      const self = this;
      var itemSelected = item;

      // console.log(item);

      if (this.saleItemsData.length <= 0) {
        // console.log("sale Item data length less that 0")
        return;
      }

      // end of find
      const currentSerial = this.serialMasterData.find((record) => {
        return record.id === itemSelected.id;
      });

      if (currentSerial) {
        return;
      }

      // end of find
      const currentItem = this.saleItemsData.find((record) => {
        return record.LineNum === self.selectedItemWithDocs[0].LineNum;
      });

      const pos = this.saleItemsData.indexOf(currentItem);
      if (pos === -1) {
        return;
      }

      var allSerialForItem = currentItem.SerialNumbers || [];
      if (allSerialForItem.length < currentItem.Quantity) {
        allSerialForItem.push(itemSelected);
        currentItem.SerialNumbers = allSerialForItem;
        this.saleItemsData[pos] = currentItem;
        this.serialMasterData = allSerialForItem;
      } else {
        this.$refs.snackbar.show(
          "Cannot add serial Number, First update quantity or delete current selected serial",
          "red"
        );
      }
    },

    removeSerialNumber(item) {
      const self = this;
      // end of find
      const currentItem = this.saleItemsData.find((record) => {
        return record.LineNum === self.selectedItemWithDocs[0].LineNum;
      });

      const posItem = this.saleItemsData.indexOf(currentItem);
      if (posItem === -1) {
        return;
      }

      var SerialNumbers = currentItem.SerialNumbers;
      const currentSerial = currentItem.SerialNumbers.find((record) => {
        return record.DistNumber === item.DistNumber;
      });
      const pos = this.serialMasterData.indexOf(currentSerial);
      if (pos === -1) {
        return;
      }

      this.serialMasterData.splice(pos, 1);
      SerialNumbers.splice(pos, 1);
      currentItem.SerialNumbers = SerialNumbers;
      this.saleItemsData[posItem] = currentItem;
    },
    setDefaults() {
      if (this.docCreate) {
        this.DocType = localStorage.getItem("docType");
      }
    },
    setCopyFromDocs(val) {
      const objectId = val;
      if (this.docCreate) {
        if (objectId == 13) {
          this.copyFromDocs = [
            { name: "--SELECT--", id: 0 },
            { name: "Sales Order", id: 17 },
            { name: "Delivery", id: 15 },
          ];
        }

        if (objectId == 15) {
          this.copyFromDocs = [
            { name: "--SELECT--", id: 0 },
            { name: "Sales Order", id: 17 },
          ];
        }

        if (objectId == 17) {
          this.copyFromDocs = [
            { name: "--SELECT--", id: 0 },
            { name: "Sales Order", id: 17 },
          ];
        }
      }
    },
    copyDocFrom(val) {
      const self = this;
      const cardCode = this.selectedTenant.CardCode;
      self.baseDocuments = [];
      if (val != 0) {
        this.$store
          .dispatch("get", `/customer_sales_doc/${val}?cardCode=${cardCode}`)
          .then((res) => {
            if (res.ResultCode == 1200) {
              self.baseDocuments = res.ResponseData;
            }
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });

        this.copyFromDialog = true;
      }
    },

    getCopyToDocument(item) {
      this.$store
        .dispatch("get", `/documents/${item.ObjType}/${item.id}?isDoc=1`)
        .then((res) => {
          let newRowData = res.document_lines;
          this.copyFromHeaderDocumentDetails = res;
          newRowData.forEach((doc) => {
            const itemsCount = this.saleItemsData.length;
            const newCount = itemsCount + 1;

            doc.rowNum = new Date().getTime();
            doc.DocEntry = null;
            doc.RowId = newCount;
            doc.id = newCount;
            doc.LineNum = itemsCount;
            doc.copiedFromObjType =
              item.ObjType != this.activeObject ? item.ObjType : null;
            doc.copiedFromBaseEntry = item.ObjType != this.activeObject ? item.id : null;
            doc.copiedFromBaseLine =
              item.ObjType != this.activeObject ? doc.LineNum : null;

            this.saleItemsData = [...this.saleItemsData, doc];
          });
        })
        .catch((err) => {
          console.log(err);
        });

      this.copyFromDialog = false;
    },

    getItemMasterData2() {
      if (this.Loading) return;
      this.loading = true;
      this.entries = [];
      const self = this;

      const searchTerm = this.search;

      this.$store
        .dispatch("get", `/item_masterdata?search=${searchTerm}`)
        .then((res) => {
          if (searchTerm == this.search) {
            self.entries = res.ResponseData.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      this.loading = false;
    },

    getItemMasterDataLocal() {
      const self = this;
      if (self.Loading) return;
      self.loading = true;
      self.entries = [];

      const url = self.localSettings.localurl + "/o_i_t_m_s?codesearch=" + this.search;
      axios.get(url, this.options).then((resp) => {
        self.loading = false;
        self.entries = resp.data;
      });
    },

    itemSearchItemCodeHasChanged(val) {
      this.pasteFromExcel = false;
      this.productChange(val);
    },

    productChange(val, currentExcelDataRow = null) {
      if (!val) {
        return;
      }
      const self = this;
      //prepare fetch item cloud url and data
      const url = "/fetch-item-with-code";
      const data = {
        ObjType: this.activeObject, //Document Type
        ItemCode: val,
        CardCode: this.selectedTenant.CardCode,
        priceList: this.GroupNum,
        SUoMEntry: null,
        type: "priceData",
      };
      if (!self.localSettings.enable) {
        this.$store
          .dispatch("post", { url, data })
          .then((res) => {
            if (res.ResultCode == 1200) {
              const selectedItem = res.ResponseData;
              this.addTableRow();
              this.setSelectBranch(this.NewBPLId);
              this.clickedItem2(selectedItem, currentExcelDataRow);
            } else {
              this.$refs.snackbar.show(res.ResultDesc, "red");
            }
          })
          .catch((err) => {
            console.log(err, "error");
          })
          .finally(() => {
            this.search = null;
            this.selectedItemCode = null;
            this.entries = [];
          });
      }

      if (self.localSettings.enable) {
        //get item from cloud after local search
        // this.$store
        //     .dispatch("post", {url, data})
        //     .then((res) => {
        //       if (res.ResultCode == 1200) {
        //         const selectedItem = res.ResponseData;
        //         this.addTableRow();
        //         this.setSelectBranch(this.NewBPLId);
        //         this.clickedItem2(selectedItem, currentExcelDataRow);
        //       }else{
        //         this.$refs.snackbar.show(res.ResultDesc, "red");
        //       }
        //     })
        //     .catch((err) => {
        //       console.log(err, "error");
        //     })
        //     .finally(() => {
        //       this.search = null;
        //       this.selectedItemCode = null;
        //       this.entries = [];
        //     });

        const url = self.localSettings.localurl + `/fetch-item-with-code`;

        axios
          .post(url, data, this.options)
          .then((res) => {
            const selectedItem = res.data.ResponseData;
            this.addTableRow();
            this.clickedItem2(selectedItem, currentExcelDataRow);
          })
          .catch((err) => {
            console.log(err, "error");
          })
          .finally(() => {
            this.search = null;
            this.selectedItemCode = null;
            this.entries = [];
          });
      }
    },

    clickedItem2(val, currentExcelDataRow = null) {
      //Chnages

      //Get ItemDetails

      const self = this;

      if (
        (this.selectedTenant.id === undefined && this.activeObject === 205) ||
        (this.selectedTenant.id === undefined && this.activeObject === 66)
      ) {
        this.selectedItems = [];
        this.itemRecord = {};
        this.itemDialog = false;
        this.dialog = false;
        this.$refs.snackbar.show("Please select Customer first!", "red");

        return;
      }
      this.selectedItem = val;
      const sItem = val;

      // const data = {
      //   ObjType: this.activeObject, //Document Type
      //   ItemCode: val.ItemCode,
      //   CardCode: this.selectedTenant.CardCode,
      //   priceList: this.GroupNum,
      //   SUoMEntry: null,
      // };

      const id = this.activeRow;

      // find the selected row and update
      const currentItem = this.saleItemsData.find((record) => {
        return record.LineNum === id;
      });

      const pos = this.saleItemsData.indexOf(currentItem);
      if (pos === -1) {
        return;
      }

      // get tax rate
      let taxId = sItem.VatGourpSa;
      let TaxCode = sItem.VatGourpSa;

      if (this.activeObject == 205) {
        taxId = sItem.VatGroupPu;
        TaxCode = sItem.VatGroupPu;
      }

      const selectedTax = this.taxGroups.find((record) => {
        return record.code === taxId;
      });

      let taxRate = 0;
      if (selectedTax) {
        taxRate = selectedTax.rate;
      }
      // end of tax get

      currentItem.Dscription = sItem.ItemName;
      currentItem.ItemCode = sItem.ItemCode;
      currentItem.UomCode = sItem.SUoMEntry;
      currentItem.UomEntry = sItem.SUoMEntry;
      currentItem.id = sItem.id;
      currentItem.Quantity = 1;
      currentItem.UseBaseUn = "N";
      currentItem.PackQty = 1;
      currentItem.PickStatus = "N";
      currentItem.U_StockWhse = 0;
      currentItem.U_ItemCost = 0;
      currentItem.WhsCode = null;
      currentItem.IsManual = null;
      currentItem.TaxCode = TaxCode;
      currentItem.ManSerNum = sItem.ManSerNum; //To User to check for serial
      currentItem.oitm = sItem;
      // end of row find

      // make prices query request
      self.updateDefaultPricesOnClickItem(currentItem, sItem, val, taxRate).then(() => {
        /**
         * Set Delaer Discount
         */
        currentItem.DiscPrcnt = 0;

        if (this.discount > 0) {
          if (sItem.QryGroup61 == "N") {
            currentItem.DiscPrcnt = this.discount > 0 ? this.discount : 0;
          }
        }

        // end
        // fetch items uoms
        const UgpEntry = this.selectedItem.UgpEntry;
        this.$store
          .dispatch("get", `/uomgroup/${UgpEntry}`)
          .then((res) => {
            currentItem.uoms = res.ResponseData.ugp1;
            const currentSUomEntryObject = res.ResponseData.ugp1.find((record) => {
              return record.uomentry.id === sItem.SUoMEntry;
            });
            // end of tax get
            currentItem.UomObject = currentSUomEntryObject.uomentry;
          })
          .catch((err) => {
            console.log(err, "error");
          })
          .finally(() => {
            // end of uoms
            // set defaults
            self.saleItemsData[pos] = currentItem;
            this.inputChanged(currentItem);
            // end of default values
            if (this.pasteFromExcel) {
              this.populatedExcelData(
                currentExcelDataRow,
                this.excelTitleRow,
                currentItem.LineNum
              );
              this.itemDialog = false;
            }
          });
      });

      // const url = "/getDefaultPrice";
      // this.$store
      //   .dispatch("post", { url, data })
      //   .then((res) => {
      //     if (res.ResultCode == 1200) {
      //       const priceData = res.ResponseData;
      //       self.updateDefaultPricesOnClickItem(currentItem,sItem,priceData,taxRate);
      //
      //       // end of computation
      //     } else {
      //       self.$refs.snackbar.show(res.ResultDesc, "red");
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     /**
      //      * Set Delaer Discount
      //      */
      //     currentItem.DiscPrcnt = 0;
      //
      //     if (this.discount > 0) {
      //       if (sItem.QryGroup61 == "N") {
      //         currentItem.DiscPrcnt = this.discount > 0 ? this.discount : 0;
      //       }
      //     }
      //
      //     // end
      //     // fetch items uoms
      //     const UgpEntry = this.selectedItem.UgpEntry;
      //
      //     this.$store
      //       .dispatch("get", `/uomgroup/${UgpEntry}`)
      //       .then((res) => {
      //         currentItem.uoms = res.ResponseData.ugp1;
      //       })
      //       .catch((err) => {
      //         console.log(err, "error");
      //       })
      //       .finally(() => {
      //         // end of uoms
      //         // set defaults
      //         self.saleItemsData[pos] = currentItem;
      //
      //         this.inputChanged(currentItem);
      //         // end of default values
      //         if (this.pasteFromExcel) {
      //           this.populatedExcelData(
      //             currentExcelDataRow,
      //             this.excelTitleRow,
      //             currentItem.LineNum
      //           );
      //           this.itemDialog = false;
      //         }
      //       });
      //   });
      // end of request
    },
    setWarehouseDefaultForAllItems() {
      if (this.defaultWarehouse) {
        if (this.saleItemsData.length > 0) {
          this.saleItemsData.forEach((doc) => {
            doc.DiscPrcnt = 0;

            doc.WhsCode = this.defaultWarehouse;
            this.updateRows(doc);
          });
        }
        this.setDefaultWarehouseDialog = false;
      }
    },
    formatStringToLowerCaseAndRemoveSpace(string) {
      return string.replace(/\s/g, "").toLowerCase();
    },
    formatStringRemoveCommas(string) {
      return string.replace(/,/g, "");
    },
    pasteExcel() {
      this.pasteFromExcel = true;
      navigator.clipboard.readText().then((clipText) => {
        this.processData(clipText);
      });
    },
    processData(data) {
      this.$store.commit("loader", true);
      var document_lines = data
        .replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/gm, function (match, p1) {
          // This function runs for each cell with multi lined text.
          return (
            p1
              // Replace any double double-quotes with a single
              // double-quote
              .replace(/""/g, '"')
              // Replace all new lines with spaces.
              .replace(/\r\n|\n\r|\n|\r/g, " ")
          );
        })
        // Split each line into document_lines
        .split(/\r\n|\n\r|\n|\r/g);

      for (let i = 0; i < document_lines.length; i++) {
        document_lines[i] = document_lines[i].split(String.fromCharCode(9));
      }

      for (let i = 0; i < document_lines.length; i++) {
        const formatItemCodeColumn = this.formatStringToLowerCaseAndRemoveSpace(
          document_lines[i][0]
        );
        if (formatItemCodeColumn === "itemcode") {
          continue;
        }

        if (!document_lines[i][0]) {
          continue;
        }

        const self = this;

        if (!this.localSettings.enable) {
          this.$store
            .dispatch("get", `/item_masterdata?search=${document_lines[i][0]}`)
            .then((res) => {
              self.entries = res.ResponseData.data;

              const currentExcelDataRow = document_lines[i];
              this.excelTitleRow = document_lines[0];

              this.productChange(document_lines[i][0], currentExcelDataRow);
              this.$store.commit("loader", false);
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        }

        if (this.localSettings.enable) {
          const url =
            self.localSettings.localurl + "/o_i_t_m_s?codesearch=" + document_lines[i][0];
          axios.get(url, this.options).then((resp) => {
            self.entries = resp.data;

            const currentExcelDataRow = document_lines[i];
            this.excelTitleRow = document_lines[0];

            this.productChange(document_lines[i][0], currentExcelDataRow);
            this.$store.commit("loader", false);
          });
        }
      }

      this.currentExcelDataRow = [];
      this.excelTitleRow = [];
    },

    populatedExcelData(currentRowExcelData, excelTitleRow, salesDataLineNum) {
      const currentRow = this.saleItemsData.find((record) => {
        return record.LineNum === salesDataLineNum;
      });
      const pos = this.saleItemsData.indexOf(currentRow);

      if (pos === -1) {
        return;
      }

      for (let k = 1; k < currentRowExcelData.length; k++) {
        const formaattedColumn = this.formatStringToLowerCaseAndRemoveSpace(
          excelTitleRow[k]
        );

        if (formaattedColumn === "itemdescription") {
          currentRow.Dscription = currentRowExcelData[k];
        } else if (formaattedColumn === "whse") {
          currentRow.WhsCode = currentRowExcelData[k];
        } else if (formaattedColumn === "quantity") {
          currentRow.Quantity = currentRowExcelData[k];
        } else if (formaattedColumn === "unitprice") {
          let formattedPrice = this.formatStringToLowerCaseAndRemoveSpace(
            currentRowExcelData[k]
          );

          let removeCommasPrice = this.formatStringRemoveCommas(formattedPrice);

          const checkIfPriceStartCurrenyKsh = removeCommasPrice.startsWith("kes");
          const checkIfPriceStartCurrenyUsd = removeCommasPrice.startsWith("usd");

          if (checkIfPriceStartCurrenyKsh || checkIfPriceStartCurrenyUsd) {
            const finalPrice = removeCommasPrice.substring(3);
            currentRow.PriceBefDi = finalPrice;
          }
        } else if (formaattedColumn === "discount%") {
          currentRow.DiscPrcnt = currentRowExcelData[k];
        }
        // else if (formaattedColumn === "uomcode") {
        //   const uomCode = currentRow.uoms.find((record) => {
        //     return record.uomentry.omCode === currentRowExcelData[k];
        //   });

        //   console.log("UOM CODE LINE 3206:" . )
        //   currentRow.UomCode = currentRowExcelData[k];
        // }
        else if (formaattedColumn === "taxcode") {
          currentRow.TaxCode = currentRowExcelData[k];
        } else if (formaattedColumn === "cogslocation") {
          currentRow.CogsOcrCod = currentRowExcelData[k];
        } else if (formaattedColumn === "cogsdepartment") {
          currentRow.CogsOcrCod = currentRowExcelData[k];
        } else if (formaattedColumn === "cogsproductline") {
          currentRow.CogsOcrCod = currentRowExcelData[k];
        } else if (formaattedColumn === "cogsstaff") {
          currentRow.CogsOcrCod = currentRowExcelData[k];
        } else if (formaattedColumn === "cogsvehicles") {
          currentRow.CogsOcrCod = currentRowExcelData[k];
        }

        this.saleItemsData[pos] = currentRow;
      }

      const currentUpdatedRow = this.saleItemsData.find((record) => {
        return record.LineNum === salesDataLineNum;
      });
      const posUpdated = this.saleItemsData.indexOf(currentUpdatedRow);

      if (posUpdated === -1) {
        return;
      }
      this.updateRows(currentUpdatedRow);
    },

    setUserFields(fieldName, val) {
      this.$emit("setUserFields", { val, fieldName });
    },
  },
  created() {
    // this.getWarehouses();
    this.setDefaults();
    //  this.getItemMaster();
    this.getActiveGLAccounts();
    this.getTaxGroups();
    // this.getCostCenters();
    this.getDistributionRules(1);
    this.getDistributionRules(2);
    this.getDistributionRules(3);
    this.getDistributionRules(4);
    this.getDistributionRules(5);
    // this.getUomGroups(1);
  },
};
</script>

<style scoped>
.v-data-table /deep/ .salesDataColumn {
  position: sticky;
  top: 0;
}
</style>
