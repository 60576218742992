<template>
  <div>
    <!--    <v-row>-->
    <v-row style="margin: 10px; display: flex; justify-content: center; align-items: center">
      <v-col cols="4">
        <v-btn style="
              position: absolute;
              top: 0;
              right: 0;
              margin: 10px;
          "
               color="primary"
               small
               dark
               @click="opensearchMpesaDialog"
               right
               absolute
        >
          <v-icon left>mdi-plus</v-icon>Assign Mpesa
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-autocomplete
            single-line
            dense
            outlined
            autocomplete="nope"
            v-model="CreditCard"
            :items="creditcards"
            :item-text="item => item.CardName"
            item-value="CreditCard"
            label="Credit Card Name"
            @change="selectGLAccount(CreditCard)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
            single-line
            dense
            outlined
            autocomplete="nope"
            v-model="CreditAcct"
            label="GL Account"
            :items="glAccounts"
            :item-text="item => item.AcctCode +'  -  '+ item.AcctName"
            item-value="AcctCode"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-text-field
            outlined
            dense
            autocomplete="nope"
            label="Credit Card Number"
            v-model="record.CrCardNum"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
            outlined
            dense
            autocomplete="nope"
            label="Valid Until"
            v-model="record.ValidUntil"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
            outlined
            dense
            autocomplete="nope"
            label="ID. No"
            v-model="record.OwnerIdNum"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
            outlined
            dense
            autocomplete="nope"
            label="Customer Ref"
            v-model="record.OwnerPhone"
        ></v-text-field>
      </v-col>
      <v-col cols="4">

        <v-autocomplete
            single-line
            dense
            outlined
            autocomplete="nope"
            v-model="record.CrTypeCode"
            label="Payment Method"
            :items="paymentMethods"
            :item-text="item => item.name"
            item-value="value"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-text-field
            type="number"
            outlined
            dense
            autocomplete="nope"
            label="Amount Due"
            v-model.number="record.CreditSum"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
            type="number"
            outlined
            dense
            readonly
            autocomplete="nope"
            label="No. Of Payments"
            v-model="record.NumOfPmnts"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
            outlined
            dense
            readonly
            autocomplete="nope"
            label="Amount Paid"
            v-model="record.CreditSum"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
            outlined
            dense
            autocomplete="nope"
            label="Paid Mpesa Tel No"
            v-model="record.U_MpesaRef"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
            outlined
            dense
            readonly
            autocomplete="nope"
            label="Mpesa Txn Numbers"
            v-model.number="record.U_MpesaTxnNo"
        ></v-text-field>
      </v-col>
    </v-row>
    <!--      <v-divider></v-divider>-->

    <!--    </v-row>-->

    <!-- dialog -->

    <!-- searchMpesaDialog -->
    <v-dialog
        v-model="searchMpesaDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            color="accent"
            dense
            flat
        >
          <v-toolbar-title>Mpesa Transaction</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              @click="searchMpesaDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-autocomplete
                    dense
                    outlined
                    autocomplete="nope"
                    v-model="TransType"
                    :items="transactionTypes"
                    :item-text="item => item.CardName"
                    item-value="CreditCard"
                    label="Select Transaction"
                ></v-autocomplete>

              </v-col>
              <v-col cols="3" v-if="TransType == 'Stk Push'">
                <v-text-field
                    outlined
                    dense
                    type="number"
                    autocomplete="nope"
                    label="Mpesa Phone Number"
                    v-model="CntPhone"
                ></v-text-field>
              </v-col>
              <v-col cols="4" v-else>
                <v-text-field
                    outlined
                    dense
                    autocomplete="nope"
                    label="Mpesa Reference Code"
                    v-model="CntCode"
                ></v-text-field>
              </v-col>
              <v-col cols="3" v-if="TransType == 'Stk Push'">
                <v-text-field
                    outlined
                    dense
                    type="number"
                    autocomplete="nope"
                    label="Amount"
                    v-model="CntAmount"
                ></v-text-field>
              </v-col>
              <v-col cols="2" v-if="TransType == 'Stk Push'">
                <v-btn v-if="initiateStk" style="position: relative"
                       color="primary"
                       small
                       dark
                       absolute
                       @click="MpesaStkPush"
                >
                  Initiate Payment
                </v-btn>
                <v-btn v-else style="position: relative"
                       color="primary"
                       small
                       dark
                       absolute
                       @click="GetMpesaTransData"
                >
                  Update Balance
                </v-btn>
              </v-col>
              <v-col cols="4" v-else>
                <v-btn style="position: relative"
                       color="primary"
                       small
                       dark
                       absolute
                       @click="filterMpesaTrans"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-row>
              <v-col cols="10">
                <v-data-table
                    :items-per-page="itemsPerPage"
                    v-model="selectedMpesa"
                    :headers="mpesaHeaders"
                    :items="mpesaTransactions"
                    show-select
                    flat
                    class="elevation-1"
                    :search="search"
                    item-key="TransID"
                ></v-data-table>
              </v-col>
              <v-col cols="2">
                <v-col cols="12">
                  <v-text-field
                      type="number"
                      outlined
                      dense
                      readonly
                      autocomplete="nope"
                      label="Amnt Selected"
                      v-model="creditCardSum"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn
                      color="primary"
                      small
                      dark
                      absolute
                      @click="addMpesaTransaction"
                  >
                    Add
                  </v-btn>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <snackbar ref="snackbar"></snackbar>
  </div>
</template>

<script>
import axios from "axios";
import transactions from "@/components/inventory/transactions/index.vue";

export default {
  data() {
    return {
      dialog: false,
      searchMpesaDialog: false,
      search: null,
      singleSelect: true,
      itemsPerPage: 5,
      record: {},
      countries: [],
      transactionTypes: [
        "Search Mpesa",
        "Stk Push"
      ],
      TransType: "Search Mpesa",
      initiateStk: true,
      transRef: "",
      mpesaCallBackRef: null,
      mpesaTransactions: [],
      selectedMpesa: [],
      mpesaHeaders: [
        {text: "Till#", value: "ActCode"},
        {text: "TransID", value: "TransID"},
        {text: "Customer Name", value: "CntName"},
        {text: "CntPhone", value: "CntPhone"},
        {text: "TransAmount", value: "TransAmount"},
        {text: "TransTime", value: "TransTime"},
        {text: "Balance", value: "Balance"},
      ],
      paymentMethods: [{name: "1", value: "2"}],
      CntCode: null,
      CntPhone: null,
      CntAmount: null,
      ActCode: null,
      glAccounts: [],
      amountDue: null,
      CreditCard: null,
      CreditAcct: null,
      rct3: [],
    };
  },
  props: {
    totalBalance: {},
    creditcards: {},
  },
  computed: {
    transactions() {
      return transactions
    },
    creditCardSum() {
      let total = 0;
      if (this.selectedMpesa.length > 0) {
        return this.selectedMpesa
            .map((item) => {
              const amount = item.Balance;
              total = +amount;
              return total;
            })
            .reduce((val, a) => {
              return val + a;
            });
      } else {
        return total;
      }
    },
    amountPaidCreditSum() {
      let total = 0;
      if (this.totalBalance >= this.creditCardSum) {
        total = this.creditCardSum;
        return total;
      }
      total = this.totalBalance;
      return total;
    },
  },
  watch: {
    totalBalance: {
      handler: "setTotalBalance",
      immediate: true,
    },
  },
  methods: {
    opensearchMpesaDialog() {
      this.record = {};
      this.searchMpesaDialog = true;
    },
    setDueDate(date) {
      this.record.DueDate = date;
    },
    getBanks() {
      const self = this;
      this.loading = true;
      this.$store
          .dispatch("get", `/bank`)
          .then((res) => {
            self.banks = res.ResponseData;
            self.loading = false;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
    },
    getActiveGLAccounts() {
      const self = this;
      this.loading = true;
      this.$store
          .dispatch("get", `/activeGLaccounts`)
          .then((res) => {
            self.glAccounts = res;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
    },
    filterMpesaTrans() {
      const self = this;
      self.loading = true;
      const refCode = self.CntCode;
      console.log(refCode);
      if (refCode == null) {
        self.$refs.snackbar.show("Please Enter Mpesa Reference Code", "red");
        self.loading = false
      } else {
        axios
            .post(
                "https://cargen.pit.co.ke/api/v1/pit/getpospayresp",
                {
                  "TransID": refCode,
                }
            )
            .then((resp) => {
              self.loading = false;
              console.log(resp.data.payload[0].BusinessShortCode)
              const transDetails = {
                ActCode: resp.data.payload[0].BusinessShortCode,
                TransID: resp.data.payload[0].TransID,
                CntName: resp.data.payload[0].FirstName,
                CntPhone: resp.data.payload[0].MSISDN,
                TransAmount: resp.data.payload[0].TransAmount,
                TransTime: resp.data.payload[0].TransTime,
                Balance: resp.data.balance,
              }
              const existingTransaction = self.mpesaTransactions.find((transaction) => transaction.TransID === transDetails.TransID);

              if (existingTransaction) {
              console.log("Transaction exists");
                self.$refs.snackbar.show("Transaction already exists", "red");
              } else {
                // MSISDN does not exist, push the new transaction
                self.mpesaTransactions.push(transDetails);
              }
              // self.mpesaTransactions.push(transDetails);
              self.CntCode = null;
              self.paymentDialog = false;
            });
      }
      // this.$store
      //   .dispatch(
      //     "get",
      //     `/third-party-payments?CreditAcct=${this.CreditAcct}&CntPhone=${this.CntPhone}&CntPhone2=${this.CntPhone2}`
      //   )
      //   .then((res) => {
      //     if (res.ResultCode == 1200) {
      //       self.mpesaTransactions = res.ResponseData;
      //       self.loading = false;
      //     } else {
      //       self.$refs.snackbar.show(res.ResultDesc, "red");
      //     }
      //   })
      //
      //   .catch((err) => {
      //     this.$refs.snackbar.show(err, "red");
      //   });
    },
    addMpesaTransaction() {
      this.rct3 = [];
      if (this.selectedMpesa.length > 0) {
        // this.$emit("mpesaData", this.selectedMpesa);
        var U_MpesaRef = null;
        var U_MpesaTxnNo = null;
        this.selectedMpesa.forEach((doc) => {
          if (U_MpesaRef == null) {
            U_MpesaRef = doc.CntPhone;
          } else {
            U_MpesaRef = U_MpesaRef + "," + doc.CntPhone;
          }
          if (U_MpesaTxnNo == null) {
            U_MpesaTxnNo = doc.TransID;
          } else {
            U_MpesaTxnNo = U_MpesaTxnNo + "," + doc.TransID;
          }
        });
        const details = {
          U_MpesaTxnNo: U_MpesaTxnNo,
          U_MpesaRef: U_MpesaRef,
          CreditAcct: this.CreditAcct,
          CreditCard: this.CreditCard,
          CrTypeCode: "2",
          NumOfPmnts: 1,
          CreditSum: this.amountPaidCreditSum,
        };
        this.record = {...this.record, ...details};
        this.$emit("mpesaData", details);
        this.searchMpesaDialog = false;
      }
    },
    setTotalBalance() {
      // console.log(data, "Total Balance");
    },
    selectGLAccount(creditcard) {
      const self = this;
      const creditCardDetails = self.creditcards.find((record) => {
        return record.CreditCard === creditcard;
      });

      this.CreditAcct = creditCardDetails.AcctCode;
      const details = {
        CreditCard: creditcard,
        CrTypeCode: "2",
        NumOfPmnts: 1,
      };
      this.record = {...this.record, ...details};
    },
    MpesaStkPush() {
      const self = this;
      const url = localStorage.getItem("apiBaseUrl")+"/mpesa-callback";
      const phone_number = self.CntPhone;
      const amount = self.CntAmount;
      const phone_number_regex = /^(?:\+254|0)[17]\d{8}$/; // Phone number format: +2547xx xxx xxx or 07xx xxx xxx
      if (phone_number_regex.test(phone_number)) {
        // Generate a random number between 1000 and 9999
        // const randomNumber = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
        // console.log(randomNumber);
// Generate a random string of characters
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let randomChars = "";
        for (let i = 0; i < 4; i++) {
          randomChars += characters.charAt(Math.floor(Math.random() * characters.length));
        }
// Append the random number and characters to the phone number
//         const refNumber = phone_number.substring(0,3) + amount + randomChars + phone_number.substring(phone_number.length -3);
        const refNumber = randomChars;
        //api call
        axios
            .post(
                "https://cargen.pit.co.ke/api/v1/pit/posstkout",
                {
                  "phone_no": phone_number,
                  "amount": amount,
                  "payingshortcode": "554554",
                  "callback_url": url,
                  "reference": refNumber,
                }
            )
            .then((resp) => {
              console.log(resp)
              const self = this;
              // self.CntAmount = null;
              // self.CntPhone = null;
              self.initiateStk = true;
              self.$refs.snackbar.show("Success Enter Mpesa Pin to complete transaction", "green");
              self.paymentDialog = false;
              setTimeout(self.GetMpesaTransData(refNumber,5),30000)
            });
      }
    },
    GetMpesaTransData(ref,retries) {
      const self = this;
      //api call
      this.$store
          .dispatch("get", `/mpesa/transaction/data?ref=${ref}`)
          .then((resp) => {
            // setInterval(self.GetMpesaTransData(ref),30000)
            self.CntCode = resp.ResponseData.transactionMpesaRef;
            self.filterMpesaTrans();
            // const transDetails = {
            //   ActCode: "554554",
            //   TransID: resp.data.payload[0].TransID,
            //   CntName: resp.ResponseData.transactionName,
            //   CntPhone: resp.ResponseData.transactionPhoneNumber,
            //   TransAmount: resp.ResponseData.transactionAmount,
            //   TransTime: resp.ResponseData.TransTime,
            //   Balance: resp.ResponseData.balance,
            // }
            // self.mpesaTransactions.push(transDetails);
          })
          .catch((err) => {
            if (retries > 0) {
              // Retry the API call after 30 seconds
              setTimeout(() => {
                self.GetMpesaTransData(ref,retries-1)
              }, 30000);
            } else {
              console.log('Maximum retries reached. Unable to get a response.');
            }
            // setTimeout(self.GetMpesaTransData(ref), 30000);
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(err, "red");
          })
    },
    mounted() {
      this.getBanks();
      this.getActiveGLAccounts();
    }
  }
}
</script>

<style scoped>

</style>