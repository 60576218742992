<template>
  <v-container fluid>
    <!-- start of hardcoded footer -->

    <v-row
        v-if="footerFields"
        dense
    >
      <v-col
          v-for="(dataField, i) in footerFields"
          v-bind:key="i"
          :cols="dataField.ColumnWidth"
          v-show="dataField.Visible == 'Y'"
      >
        <v-text-field
            dense
            outlined
            v-if="dataField.FieldName == 'TotalBeforeDiscount'"
            type="number"
            :readonly="dataField.Readonly  == 'Y'? trueValue: falseValue"
            :filled="dataField.Readonly  == 'Y'? trueValue: falseValue"
            v-model.number="totalBeforeDiscount"
            autocomplete="nope"
            :label="dataField.Label"
        ></v-text-field>

        <v-text-field
            outlined
            dense
            readonly
            v-else-if="dataField.FieldName == 'VatSum'"
            :label="dataField.Label"
            v-model="genTotalTax"
        ></v-text-field>

        <v-text-field
            outlined
            dense
            v-else-if="dataField.FieldName == 'DiscountAmount'"
            :label="dataField.Label"
            v-model="discountAmount"
        ></v-text-field>

        <v-text-field
          outlined
          readonly
          v-else-if="dataField.FieldName == 'DiscSum'"
          dense
          :label="dataField.Label"
          v-model="discountAmount"
      ></v-text-field>

        <v-text-field
            outlined
            dense
            v-else-if="dataField.FieldName == 'DiscPrcnt'"
            autocomplete="nope"
            label="Disc %"
            v-model="discount"
        ></v-text-field>

        <v-select
              outlined
              dense
              filled
              readonly
              v-else-if="dataField.FieldName == 'OwnerCode'"
              v-model="UserInput[i]"
              :items="ohems"
              :label="dataField.Label"
              :item-text="item => item.firstName +'    '+ item.lastName"
              item-value="empID"
          ></v-select>

        <v-autocomplete
            outlined
            dense
            v-else-if="dataField.FieldName == 'SlpCode'"
            v-model="UserInput[i]"
            :label="dataField.Label"
            :items="salesEmployees"
            item-text="SlpName"
            item-value="SlpCode"
        ></v-autocomplete>

        <v-textarea
            outlined
            v-else-if="dataField.FieldName == 'Comments'"
            v-model="UserInput[i]"
            :label="dataField.Label"
            document_lines="4"
        ></v-textarea>

        <v-text-field
            outlined
            dense
            readonly
            v-else-if="dataField.FieldName == 'DocTotal'"
            :label="dataField.Label"
            cols="3"
            v-model="finalTotalAmount"
        ></v-text-field>

        <v-text-field
            dense
            outlined
            v-else
            :readonly="dataField.Readonly  == 'Y'? trueValue: falseValue"
            :filled="dataField.Readonly  == 'Y'? trueValue: falseValue"
            v-model="UserInput[i]"
            autocomplete="nope"
            :label="dataField.Label"
        ></v-text-field>

      </v-col>
<!--      <v-col cols="3">-->
<!--        <v-text-field-->
<!--            outlined-->
<!--            dense-->
<!--            readonly-->
<!--            label="External Reference"-->
<!--            v-model="ExtRefDocNum"-->
<!--        ></v-text-field>-->
<!--      </v-col>-->

<!--      <v-col cols="3">-->
<!--        <v-autocomplete-->
<!--            outlined-->
<!--            dense-->
<!--            v-model="SlpCode"-->
<!--            label="Sales Employee"-->
<!--            :items="salesEmployees"-->
<!--            item-text="SlpName"-->
<!--            item-value="SlpCode"-->
<!--        ></v-autocomplete>-->
<!--      </v-col>-->

<!--      <v-col cols="3">-->
<!--        <v-text-field-->
<!--            type="number"-->
<!--            outlined-->
<!--            dense-->
<!--            readonly-->
<!--            label="Total Before Discount"-->
<!--            v-model.number="totalBeforeDiscount"-->
<!--        ></v-text-field>-->
<!--      </v-col>-->

<!--      <v-col cols="3">-->
<!--        <v-select-->
<!--            outlined-->
<!--            dense-->
<!--            filled-->
<!--            readonly-->
<!--            v-model="OwnerCode"-->
<!--            :items="ohems"-->
<!--            label="Document Owner"-->
<!--            :item-text="item => item.firstName +'    '+ item.lastName"-->
<!--            item-value="empID"-->
<!--        ></v-select>-->
<!--      </v-col>-->

<!--      <v-col cols="3">-->
<!--        <v-textarea-->
<!--            outlined-->
<!--            v-model="Comments"-->
<!--            label="Remarks"-->
<!--            document_lines="4"-->
<!--        ></v-textarea>-->
<!--      </v-col>-->

<!--      <v-col cols="3">-->
<!--        <v-text-field-->
<!--            outlined-->
<!--            dense-->
<!--            autocomplete="nope"-->
<!--            v-model="U_ServiceCall"-->
<!--            label="Service Call"-->
<!--        ></v-text-field>-->
<!--        <v-text-field-->
<!--            outlined-->
<!--            dense-->
<!--            autocomplete="nope"-->
<!--            v-model="U_DemoLocation"-->
<!--            label="Demo Location"-->
<!--        ></v-text-field>-->
<!--        <v-text-field-->
<!--            outlined-->
<!--            dense-->
<!--            autocomplete="nope"-->
<!--            v-model="U_MpesaRefNo"-->
<!--            label="MpesaRefNo"-->
<!--        ></v-text-field>-->
<!--        <v-autocomplete-->
<!--            outlined-->
<!--            dense-->
<!--            autocomplete="nope"-->
<!--            :items="transferType"-->
<!--            item-text="name"-->
<!--            item-value="value"-->
<!--            label="Transfer Type"-->
<!--            v-model="U_transferType"-->
<!--        ></v-autocomplete>-->

<!--      </v-col>-->

<!--      <v-col cols="3">-->
<!--        <v-text-field-->
<!--            outlined-->
<!--            dense-->
<!--            v-model="U_BaseDoc"-->
<!--            label="Base Document No."-->
<!--        ></v-text-field>-->
<!--        <v-text-field-->
<!--            outlined-->
<!--            dense-->
<!--            v-model="U_Technician"-->
<!--            label="Technician"-->
<!--        ></v-text-field>-->

<!--        <v-text-field-->
<!--            outlined-->
<!--            dense-->
<!--            autocomplete="nope"-->
<!--            v-model="U_SSerialNo"-->
<!--            label="Unit/Item Serial Number"-->
<!--        ></v-text-field>-->

<!--        <v-autocomplete-->
<!--            outlined-->
<!--            dense-->
<!--            autocomplete="nope"-->
<!--            :items="negativeMargins"-->
<!--            item-text="name"-->
<!--            item-value="value"-->
<!--            label="Negative Margin"-->
<!--            v-model="U_NegativeMargin"-->
<!--        ></v-autocomplete>-->

<!--      </v-col>-->

<!--      <v-col cols="3">-->
<!--        <v-autocomplete-->
<!--            outlined-->
<!--            dense-->
<!--            :items="salePipeLine"-->
<!--            item-text="name"-->
<!--            item-value="value"-->
<!--            label="Sale Pipeline"-->
<!--            v-model="U_SalePipe"-->
<!--        ></v-autocomplete>-->
<!--        <v-text-field-->
<!--            outlined-->
<!--            dense-->
<!--            v-model=" U_Location"-->
<!--            label="Location"-->
<!--        ></v-text-field>-->
<!--        <v-autocomplete-->
<!--            outlined-->
<!--            dense-->
<!--            :items="purchaseType"-->
<!--            item-text="name"-->
<!--            item-value="value"-->
<!--            label="Type of Purchase"-->
<!--            v-model="U_TypePur"-->
<!--        ></v-autocomplete>-->
<!--      </v-col>-->

<!--      <v-col cols="2">-->
<!--        <v-text-field-->
<!--            outlined-->
<!--            dense-->
<!--            autocomplete="nope"-->
<!--            label="Disc %"-->
<!--            v-model="discount"-->
<!--        ></v-text-field>-->
<!--      </v-col>-->

<!--      <v-col cols="2">-->
<!--        <v-text-field-->
<!--            outlined-->
<!--            readonly-->
<!--            dense-->
<!--            label="Discount Amount"-->
<!--            v-model="discountAmount"-->
<!--        ></v-text-field>-->
<!--      </v-col>-->

<!--      <v-col cols="2">-->
<!--        <v-text-field-->
<!--            outlined-->
<!--            dense-->
<!--            readonly-->
<!--            label="Tax"-->
<!--            v-model="genTotalTax"-->
<!--        >-->
<!--        </v-text-field>-->
<!--      </v-col>-->

<!--      <v-col cols="2">-->
<!--        <v-text-field-->
<!--            outlined-->
<!--            dense-->
<!--            label="Rounding"-->
<!--            v-model="RoundDif"-->
<!--        >-->
<!--        </v-text-field>-->
<!--      </v-col>-->

<!--      <v-col cols="4">-->
<!--        <v-row>-->
<!--          <v-text-field-->
<!--              outlined-->
<!--              dense-->
<!--              readonly-->
<!--              label="Total"-->
<!--              cols="3"-->
<!--              v-model="finalTotalAmount"-->
<!--          ></v-text-field>-->
<!--          <v-btn-->
<!--              icon-->
<!--              cols="3"-->
<!--              v-if="withPayment ==='Y'"-->
<!--              @click="openPaymentDialog"-->
<!--          >-->
<!--            <v-icon color="red">mdi-sack-percent</v-icon>-->
<!--          </v-btn>-->
<!--        </v-row>-->
<!--      </v-col>-->

      <v-col
          cols="3"
          v-show="docCreate"
      >
        <v-text-field
            outlined
            dense
            v-if="withPayment ==='Y'"
            readonly
            label="Applied Amount"
            v-model="TotalPaid"
        >
        </v-text-field>
      </v-col>

      <v-col
          cols="3"
          v-show="!docCreate"
      >
        <v-text-field
            outlined
            dense
            v-if="withPayment ==='Y'"
            readonly
            label="Applied Amount"
            v-model="PaidToDate"
        >
        </v-text-field>
      </v-col>

      <v-col
          cols="3"
          v-show="docCreate"
      >
        <v-text-field
            outlined
            dense
            v-if="withPayment ==='Y'"
            readonly
            label="Balance Due"
            v-model="TotalBalance"
        >
        </v-text-field>
      </v-col>

      <v-col
          cols="3"
          v-show="!docCreate"
      >
        <v-text-field
            outlined
            dense
            v-if="withPayment ==='Y'"
            readonly
            label="Balance Due"
            v-model="DocBalance"
        >
        </v-text-field>
      </v-col>

      <v-col
          cols="4"
      >
        <v-btn
            icon
            cols="3"
            v-if="withPayment ==='Y'"
            @click="openPaymentDialog"
        >
          <v-icon color="red">mdi-sack-percent</v-icon>
        </v-btn>

      </v-col>


    </v-row>

    <!-- start of payment means modal -->
    <v-dialog
        v-model="paymentDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            color="accent"
        >
          <v-toolbar-title>
            <v-btn
                icon
                dark
                @click="paymentDialog = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>Payment Means
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="paymentDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-row
              align="center"
              justify="center"
          >
            <v-col cols="8">
              <v-tabs background-color="tabColor">
                <v-tab>Cheque</v-tab>
                <v-tab>Bank Transfer</v-tab>
                <v-tab>MPESA/Credit Card</v-tab>
                <v-tab>Cash</v-tab>
                <v-tab-item>
                  <v-row>
                    <v-col cols="4">
                      <v-col cols="12">
                        <v-autocomplete
                            single-line
                            dense
                            outlined
                            v-model="paymentData.CheckAcct"
                            label="G/L Account"
                            :items="glCheckAccounts"
                            :item-text="item => item.AcctCode +'  -  '+ item.AcctName"
                            item-value="AcctCode"
                            style="width: 190px;"
                        ></v-autocomplete>
                      </v-col>
                    </v-col>
                    <v-col colls="8">
                      <!-- cheque dialog -->
                      <cheque-editor @chequeData="addCheque"></cheque-editor>
                      <!-- end -->
                    </v-col>
                    <v-col cols="12">
                      <!-- cheques table -->
                      <v-data-table
                          :headers="chequeHeaders"
                          :items="cheques"
                      ></v-data-table>
                      <!-- end of cheques table -->
                      <label>Cheques Total:</label>
                      {{CheckSum.toLocaleString()}}
                    </v-col>
                  </v-row>
                </v-tab-item>

                <!-- start of bank transfer -->
                <v-tab-item>
                  <v-row>
                    <v-col cols="4">
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                              single-line
                              dense
                              outlined
                              v-model="paymentData.TrsfrAcct"
                              label="G/L Account"
                              :items="cashGLAccounts"
                              :item-text="item => item.AcctCode +'  -  '+ item.AcctName"
                              item-value="AcctCode"
                              style="width: 190px;"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <DatePicker
                              @date="setTransferDate"
                              :myDate="record.TrsfrDate"
                              :title="`Posting Date`"
                          ></DatePicker>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              label="Reference"
                              v-model="paymentData.TrsfrRef"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                              label="Amount"
                              v-model="TrsfrSum"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <!-- end of bank transfer -->
                <v-tab-item>
                  <!--                  <v-row>-->
                  <!--                    <v-col>-->
                  <!-- mpesa dialog -->
                  <credit-editor
                      @mpesaData="mpesaData"
                      :totalBalance="TotalBalance"
                      :creditcards="creditcards"
                  >
                  </credit-editor>
                  <!-- mpesa dialog -->
                  <!--                    </v-col>-->
                  <!--                  </v-row>-->
                </v-tab-item>
                <!-- end of Mpesa -->

                <!-- start of cash -->
                <v-tab-item>
                  <v-col cols="4">
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                            single-line
                            dense
                            outlined
                            v-model="CashAcct"
                            label="G/L Account"
                            :items="cashGLAccounts"
                            :item-text="item => item.AcctCode +'  -  '+ item.AcctName"
                            item-value="AcctCode"
                            style="width: 190px;"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                            label="Cash Amount"
                            v-model="CashSum"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-tab-item>
                <!-- end of cash -->
              </v-tabs>
            </v-col>

            <!-- start of footer -->
            <v-col cols="8">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="8">
              <v-row>
                <!--  -->
                <v-col cols="8">
                  <v-row>
                    <v-col cols="6">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              readonly
                              filled
                              label="Overall Amount"
                              v-model="TotalAmountDue"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                              label="Balance Due"
                              v-model="TotalBalance"
                              readonly
                              filled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <!--  -->
                <v-col cols="4">
                  <v-text-field
                      label="Paid"
                      readonly
                      filled
                      v-model="TotalPaid"
                  ></v-text-field>
                </v-col>
                <!--  -->
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-btn
                      color="primary"
                      @click="checkPaymentMeans"
                  >
                    <v-icon left>mdi-plus</v-icon>Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- end of payment means m0dal -->

    <!-- Modal To show payment assigned to item --->
    <v-dialog
        v-model="paymentListDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            color="accent"
            dense
            flat
        >
          <v-toolbar-title>Payment List</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              @click="paymentListDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-data-table
                    :headers="paymentListHeaders"
                    :items="paymentList"
                    flat
                    class="elevation-1"
                    item-key="id"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Payment added to document modal -->

    <!-- end of hardcoded footer -->
  </v-container>
</template>

<script>
import ChequeEditor from "./cheque-dialog.vue";
import CreditEditor from "./credit-dialog.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    salesEmployees: {
      type: Array,
    },
    docTotal: {
      type: Number,
    },
    genDiscount: {
      type: Number,
    },
    discountAmount: {
      type: Number,
    },
    finalTotalAmount: {},
    genTotalTax: {
      type: Number,
    },
    DefaultSlp: {
      type: Number,
    },
    ExtRefDocNum: {
      type: String,
    },
    ExtRef: {
      type: String,
    },
    initial: {
      type: Object,
    },
    dealerDiscount: {
      type: Number,
    },
    withPayment: {
      type: String,
    },
    footerFields: {
      type: Array,
    },
    activeObject: {
      type: String,
    },
    docCreate: {
      type: Boolean,
    },
    copyFromHeaderDocumentDetails: {
      type: Object,
    },
  },
  data() {
    return {
      UserInput:[],
      record: {},
      discount: null,
      docReadonly: "N",
      trueValue: true,
      falseValue: false,
      // SlpCode: null,
      // Comments: null,
      // OwnerCode: null,
      //employees: [],
      paymentDialog: false,
      paymentListDialog: false,
      cheques: [],
      CashSum: 0,
      TrsfrSum: 0,
      CashAcct: null,
      TrsfrAcct: null,
      RoundDif: 0,
      orct: [],
      creditcards: [],
      rct3: [],
      paymentData: {},
      PaidToDate: null,
      DocBalance: null,
      totalBeforeDiscount: 0,
      paymentList: [],
      pettyCash: [
        { name: "Petty Cash Request", value: "Petty Cash Request" },
        { name: "Purchase Request", value: "Purchase Request" },
        { name: "EFT/Cheque Request", value: "EFT/Cheque Request" },
        { name: "IOU Clearance", value: "IOU Clearance" },
        { name: "Expense Claim", value: "Expense Claim" },
        { name: "Casual Wages", value: "Casual Wages" },
        { name: "Cheque Payment Voucher", value: "Cheque Payment Voucher" },
        { name: "Petty Cash IOU Request", value: "Petty Cash IOU Request" },
      ],
      chequeHeaders: [
        { text: "Due Date", value: "DueDate" },
        { text: "Amount", value: "CheckSum" },
        { text: "Country", value: "CountryCod" },
        { text: "Bank Name", value: "BankCode" },
        { text: "Branch", value: "Branch" },
        { text: "Account", value: "AcctNum" },
        { text: "Cheque No.", value: "CheckNum" },
        { text: "Endorse", value: "Endorse" },
      ],
      salePipeLine: [
        { name: "Chomoka Na Gari", value: "Chomoka" },
        { name: "Roadshow", value: "Websites" },
        { name: "Referalls", value: "Referalls" },
        { name: "Adverts i.e Neswspaper", value: "Adverts" },
        { name: "Repeat Customer", value: "Repeat" },
      ],
      // transferType: [
      //   { name: "Branch Transfer", value: "Branch" },
      //   { name: "Staff Technician", value: "Technician" },
      //   { name: "Van Sales", value: "VanSales" },
      //   { name: "Staff Demo", value: "StaffDemo" },
      // ],
      purchaseType: [
        { name: "Import", value: "Import" },
        { name: "Import Clearing Charge", value: "ImportClearingCharge" },
        { name: "Laptop-Computer", value: "LaptopComputer" },
        { name: "Maintenance Material", value: "MaintenanceMaterial" },
        { name: "Mileage", value: "Mileage" },
      ],
      paymentListHeaders: [
        { text: "Payment No#", value: "DocNum" },
        { text: "Posting Date", value: "orct.DocDueDate" },
        { text: "Due Date", value: "orct.DocDate" },
        { text: "Total", value: "SumApplied" },
        { text: "SAP #", value: "orct.ExtRef" },
      ],
      negativeMargins: [
        { name: "Yes", value: "Y" },
        { name: "No", value: "N" },
      ],
      glAccounts: [],
      glCheckAccounts: [],
    };
  },
  components: {
    ChequeEditor,
    CreditEditor,
  },
  watch: {
    UserInput: {
      handler: function(newValues) {
        newValues.forEach((value, i) => {
          const fieldName = this.footerFields[i].FieldName;
          this.setUserInput(fieldName, value);
        })
      },
      deep: true,
    },
    dealerDiscount: {
      handler: "setDealerDiscount",
      immediate: true,
    },
    discount: {
      handler: "setDiscount",
      immediate: true,
    },
    // SlpCode: {
    //   handler: "setSlp",
    //   immediate: true,
    // },
    DefaultSlp: {
      handler: "setSlpCode",
      immediate: true,
    },
    // ExtRef: {
    //   handler: "setExtRef",
    //   immediate: true,
    // },
    // ExtRefDocNum: {
    //   handler: "setExtRefDocNum",
    //   immediate: true,
    // },
    initial: {
      handler: "setInitial",
      immediate: true,
    },
    paymentData: {
      handler: "sendPaymentData",
      immediate: true,
      deep: true,
    },
    CashAcct: {
      handler: "setCashAcct",
      immediate: true,
    },
    CashSum: {
      handler: "setCashSum",
      immediate: true,
    },
    TrsfrSum: {
      handler: "setTrsfrSum",
      immediate: true,
    },
    CheckSum: {
      handler: "setCheckSum",
      immediate: true,
    },
    TotalPaid: {
      handler: "setTotalPaid",
      immediate: true,
    },
    cheques: {
      handler: "setcheques",
      immediate: true,
    },
    rct3: {
      handler: "setCreditCard",
      immediate: true,
    },
    docTotal: {
      handler: "setDocTotal",
      immediate: true,
    },
    RoundDif: {
      handler: "setRoundDif",
      immediate: true,
    },
    copyFromHeaderDocumentDetails: {
      handler: "setCopyFromHeaderDocumentDetails",
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(
        {
          ohems:"ohem/ohems",
          getCreditCards: "getCreditCards",
          getGlAccounts: "getActiveGlAccounts",
        }
    ),
    saleItemsData() {
      return this.record.inv1;
    },
    TotalPaid() {
      const total =
          this.CheckSum +
          parseFloat(this.TrsfrSum) +
          parseFloat(this.CashSum) +
          this.CreditCardSum;
      return total;
    },
    TotalBalance() {
      return this.TotalAmountDue - this.TotalPaid;
      // return balance;
    },
    TotalAmountDue() {
      return this.finalTotalAmount;
    },
    CheckSum() {
      let total = 0;
      if (typeof this.cheques != "undefined" && this.cheques.length > 0) {
        return this.cheques
            .map((item) => {
              const amount = item.CheckSum;
              total = +amount;
              return total;
            })
            .reduce((val, a) => {
              return val + a;
            });
      } else {
        return total;
      }
    },
    CreditCardSum() {
      let total = 0;
      if (this.rct3.length > 0) {
        return this.rct3
            .map((item) => {
              const amount = item.CreditSum;
              total = +amount;
              return total;
            })
            .reduce((val, a) => {
              return val + a;
            });
      } else {
        return total;
      }
    },
    CurrentlyLoginUser() {
      return this.$store.state.user;
    },
    cashGLAccounts() {
      return this.glAccounts.filter(function (e) {
        if (e.Finanse == "Y") {
          return e;
        }
      });
    },
    employees() {
      return this.$store.state.salesEmployees;
    },
  },
  methods: {
    setCopyFromHeaderDocumentDetails(val) {
      if (!this.docCreate) {
        return;
      }
      if (val) {
        this.Comments = val.Comments;
        this.SlpCode = val.SlpCode;
        this.U_NegativeMargin = val.U_NegativeMargin;
      }
    },

    setInitial(val) {
      if (val) {
        this.footerFields.forEach((footer, i)=>{
          const fName = footer.FieldName;
          if (val.hasOwnProperty(fName)) {
            this.UserInput[i] = val[fName];
          }
        });
        // this.Comments = val.Comments;
        // this.discount = val.DiscPrcnt;
        // this.SlpCode = val.SlpCode;
        this.PaidToDate = val.formattedPaidToDate;
        // this.U_SSerialNo = val.U_SSerialNo;
        // this.U_BaseDoc = val.U_BaseDoc;
        this.DocBalance = val.formattedBalance;
        this.paymentList = val.payments;
        if (!this.docCreate) {
          // this.U_NegativeMargin = val.U_NegativeMargin;
          this.OwnerCode = val.OwnerCode;
        }
      }
    },

    setDealerDiscount() {},

    setDiscount(data) {
      this.$emit("discount", data);
    },

    // setOwnerCode(data) {
    //   this.$emit("OwnerCode", data);
    // },
    // setComments(data) {
    //   this.$emit("Comments", data);
    // },
    // serSerialNo(data) {
    //   this.$emit("U_SSerialNo", data);
    // },
    // setServiceCall(data) {
    //   this.$emit("U_ServiceCall", data);
    // },
    // setDemolocation(data) {
    //   this.$emit("U_DemoLocation", data);
    // },
    // setMpesaRefNo(data) {
    //   this.$emit("U_MpesaRefNo", data);
    // },
    // setTechnician(data) {
    //   this.$emit("U_Technician", data);
    // },
    // setLocation(data) {
    //   this.$emit("U_Location", data);
    // },
    setSlp(data) {
      this.$emit("SaleEmployee", data);
    },
    setSalePipe(val) {
      this.$emit("selectedSalePipe", val);
    },
    // setTransferType(val) {
    //   this.$emit("U_transferType", val);
    // },
    // setPurchaseType(val) {
    //   this.$emit("U_TypePur", val);
    // },
    // setU_NegativeMargin(val) {
    //   this.$emit("setU_NegativeMargin", val);
    // },
    // setU_BaseDoc(val) {
    //   this.$emit("setU_BaseDoc", val);
    // },
    setSlpCode(val) {
      if (val) {
        this.footerFields.forEach((footer, i)=>{
          if (footer.FieldName == "SlpCode"){
            this.UserInput[i] = val;
          }
        });
        // this.SlpCode = val;
      }
    },
    setRoundDif(val) {
      this.$emit("setRoundDif", val);
    },
    setDocTotal(val) {
      this.totalBeforeDiscount = 0;
      if (val) {
        this.totalBeforeDiscount = parseFloat(val.toFixed(2));
      }
    },
    addCheque(data) {
      this.cheques.push(data);
    },
    mpesaData(data) {
      this.rct3 = [];
      this.rct3.push(data);
    },
    setTransferDate(data) {
      const details = { TrsfrDate: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setCashAcct(data) {
      const details = { CashAcct: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setCashSum(data) {
      const details = { CashSum: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setTrsfrAcct(data) {
      const details = { TrsfrAcct: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setTrsfrSum(data) {
      const details = { TrsfrSum: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setcheques(data) {
      const details = { cheques: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setCreditCard(data) {
      const details = { rct3: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setCheckSum(data) {
      const details = { CheckSum: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setTotalPaid(data) {
      const details = { TotalPaid: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    sendPaymentData(data) {
      if (this.TotalPaid > 0) {
        this.$emit("salePaymentData", data);
      }
    },
    getActiveGLAccounts() {
      const self = this;
      if (self.getGlAccounts["Default"]){
        self.glAccounts = self.getGlAccounts["Default"];
      }else {
        this.loading = true;
        this.$store
            .dispatch("get", `/activeGLaccounts`)
            .then((res) => {
              var glAccounts = { ...this.getGlAccounts };
              glAccounts["Default"] = res;
              this.$store.commit("setActiveGlAcounts", glAccounts);
              self.glAccounts = res;
            })
            .catch((err) => {
              this.$refs.snackbar.show(err, "red");
            });
      }
    },
    getCheckActiveGLAccounts() {
      const self = this;
      if (self.getGlAccounts["Checks"]){
        self.glCheckAccounts = self.getGlAccounts["Checks"];
      }else {
        this.loading = true;
        this.$store
            .dispatch("get", `/activeGLaccounts?type=Checks`)
            .then((res) => {
              var glAccounts = { ...this.getGlAccounts };
              glAccounts["Checks"] = res;
              this.$store.commit("setActiveGlAcounts", glAccounts);
              self.glCheckAccounts = res;
            })
            .catch((err) => {
              console.log(err)
            });
      }
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
          .dispatch("get", `/iemployee`)
          .then((res) => {
            self.employees = res.ResponseData;
            self.loading = false;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
    },

    setDefaultOwnerCode() {
      this.footerFields.forEach((footer, i)=>{
        if (footer.FieldName == "OwnerCode"){
          this.UserInput[i] = this.CurrentlyLoginUser.EmpID;
        }
      });
    },
    openPaymentDialog() {
      if (this.docCreate) {
        this.paymentDialog = true;
      }
      if (!this.docCreate) {
        this.paymentListDialog = true;
      }
    },
    checkPaymentMeans(){
      const self = this;
      self.paymentDialog = false;
      // let data = self.paymentData;
      // self.startStkPush(data);
    },
    getCreditCard() {

          const self = this;

          if (self.getCreditCards){
            self.creditcards = self.getCreditCards;
          }else {
            this.loading = true;
            this.$store
                .dispatch("get", `/credit-card`)
                .then((res) => {
                  self.creditcards = res.ResponseData;
                  self.$store.commit("setCreditCards",res.ResponseData);
                  self.loading = false;
                })
                .catch((err) => {
                  this.$refs.snackbar.show(err, "red");
                });
          }
      },

    setUserInput(fieldName,val) {
      this.$emit("setUserInput", {val, fieldName});
    }
  },
  created() {
    this.getActiveGLAccounts();
    this.getCheckActiveGLAccounts();
    ///this.getEmployees();
    this.getCreditCard();
    this.setDefaultOwnerCode();
    // console.log(this.CurrentlyLoginUser);
  },
};
</script>

<style lang="scss" scoped>
</style>