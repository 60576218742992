export function lineTotals(data) {
  // compute price;
  let Price = 0;
  const discountAmount = (data.Discount / 100) * data.PriceBefDi;
  Price = data.PriceBefDi - discountAmount;

  // compute LineTotal
  let LineTotal = 0;
  if (data.Discount > 0) {
    LineTotal = Price * data.Quantity;
  } else {
    LineTotal = data.PriceBefDi * data.Quantity;
  }

  // PriceAfVAT - Gross Price
  let PriceAfVAT = 0;
  let taxAmount = 0;
  taxAmount = (data.Tax / 100) * Price;
  PriceAfVAT = taxAmount + Price;

  // VatSum - Total Tax - Row
  let itemTax = 0;
  itemTax = (data.Tax / 100) * data.PriceBefDi;
  const totalTax = itemTax * data.Quantity;
  const taxDiscount = (data.Discount / 100) * totalTax;
  const VatSum = totalTax - taxDiscount;

  // GTotal -  gross total
  const GTotal = LineTotal + VatSum;

  const computedValues = {
    Price: Price,
    LineTotal: LineTotal,
    PriceAfVAT: PriceAfVAT,
    VatSum: VatSum,
    GTotal: GTotal,
  };

  return computedValues;
}
